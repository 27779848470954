import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectSortEDL({ onChangeParent }) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChangeParent(value);
  };

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <InputLabel htmlFor="grouped-select">Trier par :</InputLabel>
      <Select
        size="small"
        defaultValue="date-desc"
        id="grouped-select"
        label="Trier par :"
        sx={{ backgroundColor: 'white' }}
        onChange={handleChange}
      >
        <MenuItem value="date-desc">Date ↑</MenuItem>
        <MenuItem value="date-asc">Date ↓</MenuItem>
        <MenuItem value="locality-asc">Ville ↑</MenuItem>
        <MenuItem value="locality-desc">Ville ↓</MenuItem>
      </Select>
    </FormControl>
  );
}
