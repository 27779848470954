import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'; // mes EDL
import DescriptionIcon from '@mui/icons-material/Description'; // invoice
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'; // admin
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'; // profil

export const menuItems = {
  app: {
    myEDLs: {
      label: 'Mes EDLs',
      icone: <DirectionsRunIcon />,
    },
    newEDL: {
      label: 'Créer un EDL',
      icone: <AddCircleOutlineIcon />,
    },
    profil: {
      label: 'Profil',
      icone: <PersonOutlineIcon />,
    },
    invoice: {
      label: 'Facturation',
      icone: <DescriptionIcon />,
    },
    admin: {
      label: 'Administration',
      icone: <AdminPanelSettingsIcon />,
    },
    test: {
      label: 'Test',
      icone: <PersonOutlineIcon />,
    },
  },
};
