import React, { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';

import { getPropByString } from '../../../../../utils';

function TextFieldSky(props) {
  const { name, formik, value: valueFormik, formIsSubmit, ...newProps } = props;

  const [value, setValue] = useState(valueFormik ? valueFormik : '');

  const timeoutId = useRef(null);

  const registerValue = (value) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      if (['possession.area'].includes(name)) {
        value = parseFloat(parseFloat(value).toFixed(2));
      }
      if (['possession.numFloor'].includes(name)) {
        value = parseInt(value);
      }
      if (typeof value === 'string' && value.trim() === '') {
        value = null;
      }
      formik.setFieldValue(name, value, formIsSubmit);
    }, 300);
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.value === null) return;
    setValue(e.target.value);
    registerValue(e.target.value);
  };

  useEffect(() => {
    if (['possession.numFloor'].includes(name)) {
      setValue(parseInt(valueFormik));
    } else setValue(valueFormik ? valueFormik : '');
  }, [valueFormik, name]);

  const error = getPropByString(formik.errors, name);

  return (
    <TextField
      {...newProps}
      fullWidth
      name={name}
      size="small"
      onChange={handleChange}
      value={value}
      error={Boolean(error)}
      helperText={error}
      inputProps={{ style: { fontFamily: 'Poppins' } }} // font size of input text
      InputLabelProps={{
        style: { fontFamily: 'Poppins', fontSize: '14px' },
      }}
    />
  );
}

export default TextFieldSky;
