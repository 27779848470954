import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import dayjs from 'dayjs';

export default function SelectBookingProvider({ datasEdl, handleDialog }) {
  const [open, setOpen] = useState(true);

  const handleClose = (btn) => {
    if (btn === 'acceptBookingProvider') {
      const heure = dayjs.tz(datasEdl.manage.dateEDL).hour();
      if (heure < 7 || heure >= 22) return;
    }
    setOpen(false);
    handleDialog({ btn });
  };

  const handleChange = (dateBooking) => {
    datasEdl.manage.dateEDL = dayjs.tz(dateBooking).toDate();
  };

  const heure = dayjs.tz(datasEdl.manage.dateEDL).hour();
  if (heure < 7 || heure >= 22) {
    datasEdl.manage.dateEDL = dayjs.tz(datasEdl.manage.dateEDL).set('hour', 7).toDate();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" color="primary">
        Date et heure du RDV
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid>
            <Typography variant="body">Veuillez indiquer la date et l'heure du RDV prise avec le locataire</Typography>
          </Grid>
          <Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DateTimePicker
                onChange={handleChange}
                minDate={dayjs().add(2, 'day')}
                maxDate={dayjs().add(6, 'month')}
                value={dayjs.tz(datasEdl.manage.dateEDL)}
                minTime={dayjs().set('hour', 7).startOf('hour')}
                maxTime={dayjs().set('hour', 22).startOf('hour')}
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => handleClose('cancelBookingProvider')}>Annuler</Button>
        <Button onClick={(e) => handleClose('acceptBookingProvider')} autoFocus>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
