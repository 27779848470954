import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

function ButtonGroupSky({ name, arrayValues, value, formik }) {
  const handleClick = (e, value) => {
    e.preventDefault();
    formik.setFieldValue(name, value, false);
  };

  const buttons = arrayValues.map((label, index) => {
    return (
      <Button
        key={index}
        type="radio"
        variant={value === label ? 'contained' : 'outlined'}
        onClick={(e) => handleClick(e, label)}
      >
        {label}
      </Button>
    );
  });

  return (
    <ButtonGroup disableElevation name={name}>
      {buttons}
    </ButtonGroup>
  );
}

export default ButtonGroupSky;
