import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';

export default function ConfirmlDialogSky({ open, handleConfirmClose, btn, children }) {
  const buttons = () => {
    const btnCancel = (
      <Button
        variant="outlined"
        color="error"
        startIcon={<CancelIcon />}
        onClick={() => handleConfirmClose('cancel')}
        sx={{ width: '150px' }}
      >
        Annuler
      </Button>
    );

    const btnAccept = (
      <Button
        variant="contained"
        endIcon={<SendIcon />}
        onClick={() => handleConfirmClose(btn)}
        sx={{ width: '150px' }}
        autoFocus
      >
        Confirmer
      </Button>
    );

    return (
      <>
        {btnCancel}
        {btnAccept}
      </>
    );
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: { xs: 'auto' },
          backgroundColor: '#f5f5f5',
          zIndex: 20,
        },
      }}
      maxWidth={'md'}
    >
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        {children}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>{buttons()}</DialogActions>
    </Dialog>
  );
}
