import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';

export default function PrimarySearchAppBar({ drawerWidth, handlelickMenu, profil }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar
          variant="dense"
          sx={{
            width: {
              sm: `calc(100% - ${drawerWidth}px)`,
            },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handlelickMenu}
            sx={{
              mr: 2,
              display: { sm: 'none' },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <b>{profil.company.name}</b>
            {' - '}
            {profil.user.name + ' ' + profil.user.surname}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {/*<Box sx={{ display: 'flex' }}>
            <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={3} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Box>*/}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
