import { calcPrice, getDistance } from '../../api-service-common';
import { getGeo } from '../../utils';
import { dbFirebase } from '../../config';

import { collection, getDocs, limit, query, where } from 'firebase/firestore';

export default async function getListProviders(datasEdl) {
  const _getDistance = async (companyGeopoint, edlGeoppoint) => {
    const companyGeo = getGeo(companyGeopoint);
    const edlGeo = getGeo(edlGeoppoint);
    const gDistance = await getDistance(companyGeo, edlGeo);
    return gDistance;
  };

  const queryProviderCompanies = query(collection(dbFirebase, 'companies'), where('type', '==', 'provider'));
  const querySnapshot = await getDocs(queryProviderCompanies);
  const promises = querySnapshot.docs.map(async (company) => {
    const queryPriceList = query(
      collection(dbFirebase, 'companies', company.data().name, 'priceList'),
      where('status', '==', 'active'), // Tri par ordre chronologique décroissant
      limit(1), // Limite la requête à un seul document
    );

    const priceListSnapshot = await getDocs(queryPriceList);
    const latestDocument = priceListSnapshot.docs[0]; // Récupère le premier (et seul) document de la liste
    const priceListProvider = latestDocument.data(); // Données du document

    const distance = await _getDistance(company.data().address.geopoint, datasEdl.possession.address.geopoint);

    const queryUsers = query(collection(dbFirebase, 'companies', company.data().name, 'users'));

    const querySnapshot = await getDocs(queryUsers);

    const userDocs = querySnapshot.docs.map(async (user) => {
      const docPromiseObj = {
        user: user.data(),
        company: company.data(),
        pricing: calcPrice(datasEdl, distance, priceListProvider),
      };
      return docPromiseObj;
    });
    const results = await Promise.all(userDocs);
    return results;
  });

  const listUsers = await Promise.all(promises);
  return listUsers.flat();
}
