import { useState, createContext, useEffect, useContext } from 'react';
import { useUserContext } from './AuthProvider';

import { firebaseConfig } from '../config';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

const AppContextProvider = ({ children }) => {
  const { currentUser } = useUserContext();
  const [profil] = useState(() => JSON.parse(localStorage.getItem('profil')));

  const [loadedGoogleMaps, setLoadedGoogleMaps] = useState(false);

  useEffect(() => {
    console.log(process.env.REACT_APP_WITH_INTERNET);
    if (process.env.REACT_APP_WITH_INTERNET !== 'false') {
      const initMap = () => {
        setLoadedGoogleMaps(true);
      };
      window.initMap = initMap;

      if (!window.google) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${firebaseConfig.apiKey}&libraries=places&v=weekly&callback=initMap`;
        script.defer = true;
        script.async = true;
        document.head.appendChild(script);
        script.addEventListener('load', initMap);
      } else {
        window.initMap();
      }
    }
  }, []);

  return <AppContext.Provider value={{ currentUser, profil, loadedGoogleMaps }}>{children}</AppContext.Provider>;
};

export { AppContext };

export default AppContextProvider;
