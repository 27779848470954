import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Typography } from '@mui/material';
import InfosEDL from './InfosEDL';
import PricingEDL from './PricingEDL';
import PricingEDLAssignProvider from './PricingEDLAssignProvider';
import { getStylesPapers } from '../../../Theme';
import InfosUser from './InfosUser';

export default function ViewEDL({ datasEdl, profil }) {
  const typeEDL = datasEdl.manage.typeEDL === 'entrant' ? "d'entrée" : 'de sortie';
  const color = getStylesPapers(datasEdl?.metadatas?.status ?? 'submit');

  const cardPrice = () => {
    return (
      <>
        {!(profil.company.type === 'admin' && Object.keys(datasEdl).includes('provider')) && (
          <Grid md={12} lg={6}>
            <PricingEDL
              datasEdl={datasEdl}
              color={color}
              typeCompany={profil.company.type === 'admin' ? 'customer' : profil.company.type}
            />
          </Grid>
        )}
        {profil.company.type === 'admin' && Object.keys(datasEdl).includes('provider') && (
          <Grid md={12} lg={6}>
            {<PricingEDLAssignProvider datasEdl={datasEdl} color={color} />}
          </Grid>
        )}
      </>
    );
  };

  return (
    <Box width="100%" marginBottom="30px">
      <Grid container disableEqualOverflow spacing={{ xs: 1, md: 2 }} paddingBottom={2} justifyContent="stretch">
        <Grid xs={12}>
          <Typography variant="body2" color="#757575">
            Demande créée par {datasEdl?.customer?.user?.name} {datasEdl?.customer?.user?.surname} -{' '}
            {datasEdl?.customer?.user?.company}
          </Typography>
          <Typography variant="h6" color={color} component="div">
            Etat des lieux {typeEDL} à {datasEdl.possession.address.locality}{' '}
          </Typography>
        </Grid>
        <Grid xs={12} lg={6}>
          <InfosEDL datasEdl={datasEdl} color={color} typeCompany={profil.company.type} />
        </Grid>
        {cardPrice()}
        <Grid xs={12} lg={6}>
          <InfosUser datasEdl={datasEdl} color={color} typeCompany="customer" />
        </Grid>
        <Grid xs={12} lg={6}>
          <InfosUser datasEdl={datasEdl} color={color} typeCompany="provider" />
        </Grid>
      </Grid>
    </Box>
  );
}
