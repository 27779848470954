import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function CardUser({ user }) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 20, mb: 1.5 }} color="text.secondary">
          Utilisateur
        </Typography>
        <Typography color="text.secondary">
          {user.name} {user.surname}
        </Typography>
        <Typography variant="body1">Profil : {user.profil}</Typography>
        <Typography variant="body1">Téléphone : {user.phone}</Typography>
        <Typography variant="body1">Email : {user.email}</Typography>
      </CardContent>
    </Card>
  );
}
