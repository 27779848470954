import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { capitalizeFirstLetter } from '../../utils';

function createData(type, baseTarif, maxSuperficie, majoSuperficie, majoMeuble, majoVideo) {
  return {
    type,
    baseTarif,
    maxSuperficie,
    majoSuperficie,
    majoMeuble,
    majoVideo,
  };
}

const getRows = (typeHouse, pricingGrid) => {
  const order = ['T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8'];
  const rows = [];
  order.forEach((type) => {
    rows.push(
      createData(
        type,
        pricingGrid[typeHouse][type].baseTarif,
        pricingGrid[typeHouse][type].maxSuperficie,
        pricingGrid[typeHouse][type].majoSuperficie,
        pricingGrid[typeHouse][type].majoMeuble,
        pricingGrid[typeHouse][type].majoVideo,
      ),
    );
  });
  return rows;
};

export default function PricingGrid({ typeHouse, priceList }) {
  const rows = useMemo(() => getRows(typeHouse, priceList), [priceList, typeHouse]);

  return (
    <Grid container padding={0}>
      <Grid xs={12} textAlign="center">
        <Typography variant="body">{capitalizeFirstLetter(typeHouse)}</Typography>
      </Grid>

      <Grid xs={12}>
        <TableContainer component={Paper} elevation={6}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow sx={{ fontSize: '8px', whiteSpace: 'nowrap' }}>
                <TableCell>Type</TableCell>
                <TableCell align="right">Tarif de base</TableCell>
                <TableCell align="right">Sup. Max</TableCell>
                <TableCell align="right">Ex. Superficie</TableCell>
                <TableCell align="right">Meublé</TableCell>
                <TableCell align="right">Vidéo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.type} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.type}
                  </TableCell>
                  <TableCell align="right">{row.baseTarif} €</TableCell>
                  <TableCell align="right">{row.maxSuperficie} m²</TableCell>
                  <TableCell align="right">{String(row.majoSuperficie).replace('.', ',')} € / m²</TableCell>
                  <TableCell align="right">{row.majoMeuble} €</TableCell>
                  <TableCell align="right">{row.majoVideo} €</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
