import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function CardCompany({ company }) {
  return (
    <Card sx={{ minWidth: 275, height: '100%' }}>
      <CardContent>
        <Typography sx={{ fontSize: 20, mb: 1.5 }} color="text.secondary">
          Société
        </Typography>
        <Typography color="text.secondary">{company.name}</Typography>
        <Typography variant="body1">{company.address.street}</Typography>
        <Typography variant="body1">
          {company.address.zipCode} {company.address.locality}
        </Typography>
      </CardContent>
    </Card>
  );
}
