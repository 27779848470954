import React from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import { normalizePhoneNumber } from '../../../utils';
import './viewEDL.css';

import { PaperViewEDL } from '../../../Theme';

export default function InfosUser({ datasEdl, color, typeCompany }) {
  return (
    <PaperViewEDL color={color}>
      <Typography
        variant="h6"
        color={color}
        sx={{
          fontWeight: 'bold',
          mb: 2,
          fontSize: '20px',
        }}
      >
        Fiche {typeCompany === 'customer' ? 'Gestionnaire' : 'Expert EDL'}
      </Typography>

      {(Object.keys(datasEdl).includes(typeCompany) && (
        <Grid container spacing={0} xs={12}>
          <Grid container xs flexDirection="column">
            <Grid xs={12}>
              <Typography variant="body">{typeCompany === 'customer' ? 'Gestionnaire' : 'Expert EDL'}</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="body">Téléphone</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="body">E-mail</Typography>
            </Grid>
            <Grid xs sx={{ my: 1 }}>
              <Divider />
            </Grid>
            <Grid xs={12}>
              <Typography variant="body">Société</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="body">Adresse</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="body"> </Typography>
            </Grid>
          </Grid>

          <Grid container xs="auto" flexDirection="column">
            <Grid>
              <Typography variant="body1" className="edlInfos-item">
                {datasEdl[typeCompany]?.user?.name} {datasEdl[typeCompany]?.user?.surname}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body1" className="edlInfos-item">
                {normalizePhoneNumber(datasEdl[typeCompany]?.user?.phone)}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body1" className="edlInfos-item">
                {datasEdl[typeCompany]?.user?.email}
              </Typography>
            </Grid>
            <Grid xs sx={{ my: 1 }}>
              <Divider />
            </Grid>
            <Grid>
              <Typography variant="body1" className="edlInfos-item">
                {datasEdl[typeCompany]?.user?.company}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body1" className="edlInfos-item">
                {datasEdl[typeCompany]?.company?.address.street}
              </Typography>
            </Grid>
            {datasEdl[typeCompany]?.company?.address?.complement && (
              <Grid>
                <Typography variant="body1" className="edlInfos-item">
                  {datasEdl[typeCompany].company.address.complement}
                </Typography>
              </Grid>
            )}
            <Grid>
              <Typography variant="body1" className="edlInfos-item">
                {datasEdl[typeCompany]?.company?.address?.zipCode} {datasEdl[typeCompany]?.company?.address?.locality}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )) || (
        <Grid container spacing={0} xs={12}>
          <Grid container xs flexDirection="column">
            <Grid xs={12}>
              <Typography variant="body">Statut</Typography>
            </Grid>
          </Grid>

          <Grid container xs="auto" flexDirection="column">
            <Grid>
              <Typography variant="body1" className="edlInfos-item">
                A venir
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </PaperViewEDL>
  );
}
