import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export default function DialogRefuse({ handleDialog, status }) {
  const [open] = useState(true);
  const [motif, setMotif] = useState('');
  const [isError, setError] = useState(false);
  const isConfirm = useRef(false);

  const handleClose = (btn) => {
    isConfirm.current = true;
    if (btn === 'cancelRefused') {
      handleDialog({ btn });
      return;
    }
    if (typeof btn === 'string') {
      if (handleControlMotif({ target: { value: motif } })) {
        handleDialog({ btn, motif });
        return;
      }
    }
  };

  const handleControlMotif = (e) => {
    setMotif(e.target.value);
    if (isConfirm.current) {
      if (e.target.value.length < 10) {
        setError(true);
        return false;
      } else {
        setError(false);
        return true;
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          minWidth: { xs: '100%', sm: '50%' },
          minHeight: { xs: '100%', sm: '50%' },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" color="error">
        REFUS
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} textAlign={'center'}>
          <Grid xs={12}>
            <Typography variant="body" color="error">
              Veuillez indiquer le motif du refus
            </Typography>
          </Grid>
          <Grid xs={12}>
            <TextField
              sx={{ width: '400px' }}
              multiline
              inputProps={{ maxLength: 301 }}
              rows={4}
              autoFocus
              value={motif}
              onChange={(e) => handleControlMotif(e)}
              helperText={isError && 'Renseigner plus de 10 caractères minimun'}
              error={isError}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose('cancelRefused')}>Annuler</Button>
        <Button onClick={() => handleClose(status)}>Confirmer</Button>
      </DialogActions>
    </Dialog>
  );
}
