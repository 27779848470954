import React, { useEffect, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import SelectSky from '../atom/SelectSky';

import AnnexesDyn from '../molecule/AnnexesDyn';
import { useFormik } from 'formik';
import { cloneDeep } from 'lodash';

const SelectInfosAnnexes = ({ formik, formIsSubmit, $NB_MAX_ANNEXES, defaultValues, setNewPossession }) => {
  const optionsNbAnnexes = useRef(Array.from(Array($NB_MAX_ANNEXES + 1).keys()));

  const internFormik = useFormik({
    initialValues: defaultValues,
  });

  const newFormik = formik ?? internFormik;

  useEffect(() => {
    if (internFormik?.values?.possession) {
      let newPossession = cloneDeep(internFormik.values.possession);
      newPossession = { annexes: newPossession['annexes'] };
      newPossession['area'] = internFormik.values.possession.area;
      newPossession['typeHouse'] = internFormik.values.possession.typeHouse;
      newPossession['nbRoom'] = internFormik.values.possession.nbRoom;
      setNewPossession(newPossession);
    }
  }, [internFormik.values.possession, setNewPossession]);

  return (
    <>
      {!formik && (
        <Grid xs="auto">
          <SelectSky
            name="possession.nbRoom"
            label="Pièces"
            options={['T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8']}
            value={newFormik.values.possession.nbRoom}
            formik={newFormik}
          />
        </Grid>
      )}
      <Grid xs="auto">
        <AnnexesDyn
          name="possession.annexes.caves"
          label="Caves"
          options={optionsNbAnnexes.current}
          value={newFormik.values.possession.annexes.nbCaves}
          formik={newFormik}
          formIsSubmit={formIsSubmit}
        />
      </Grid>
      <Grid xs="auto">
        <AnnexesDyn
          name="possession.annexes.parkings"
          label="Parkings"
          options={optionsNbAnnexes.current}
          value={newFormik.values.possession.annexes.nbParkings}
          formik={newFormik}
          formIsSubmit={formIsSubmit}
        />
      </Grid>

      <Grid xs="auto">
        <SelectSky
          name="possession.annexes.nbBalcony"
          label="Balcons"
          options={optionsNbAnnexes.current}
          value={newFormik.values.possession.annexes.nbBalcony}
          formik={newFormik}
        />
      </Grid>

      <Grid xs="auto">
        <SelectSky
          name="possession.annexes.nbGarden"
          label="Jardins"
          options={optionsNbAnnexes.current}
          value={newFormik.values.possession.annexes.nbGarden}
          formik={newFormik}
        />
      </Grid>
      <Grid xs="auto">
        <SelectSky
          name="possession.annexes.nbOther"
          label="Autres"
          options={optionsNbAnnexes.current}
          value={newFormik.values.possession.annexes.nbOther}
          formik={newFormik}
        />
      </Grid>
    </>
  );
};

export default SelectInfosAnnexes;
