import dayjs from 'dayjs';

export const $NB_MAX_TENANTS = 5;
export const $NB_MAX_ANNEXES = 3;
export const $NB_MAX_FLOOR = 30;

const getDefaultValues = () => {
  const defaultValues = {
    manage: {
      dateEDL: dayjs.tz().add(2, 'day').startOf('day').toDate(),
      typeEDL: 'sortant',
      video: 'non',
    },
    tenants: [
      {
        civility: 'Mme',
        name: null,
        surname: '',
        email: null,
        phone: '',
      },
    ],
    possession: {
      address: {
        street: null,
        complement: null,
        zipCode: null,
        locality: null,
        region: 'IDF',
        geopoint: null,
      },
      nameOwner: '',
      typeHouse: 'appartement',
      typeRent: 'nu',
      nbRoom: 'T2',
      area: 50,
      numFloor: 0,
      annexes: {
        caves: [],
        parkings: [],
        nbParkings: 0,
        nbCaves: 0,
        nbBalcony: 0,
        nbGarden: 0,
        nbOther: 0,
      },
    },
    grades: null,
  };
  if (process.env.REACT_APP_WITH_INTERNET === 'false') {
    defaultValues.possession.address = {
      street: '2 rue du fromental',
      zipCode: '95530',
      locality: 'LA FRETTE SUR SEINE',
      region: 'IDF',
      geopoint: { latitude: 4.2321312, longitude: 23.2323 },
    };
    defaultValues.possession.nameOwner = 'COTTREZ';
    defaultValues.tenants[0].surname = 'HENRI';
    defaultValues.tenants[0].phone = '0616405916';
  }

  return defaultValues;
};

export default getDefaultValues;
