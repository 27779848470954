import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectSmall({ name, label, options, value, formik, onChange: onChangeParent }) {
  const handleChange = (event) => {
    formik.setFieldValue(name, event.target.value, false);
    if (typeof onChangeParent === 'function') onChangeParent({ [name]: event.target.value });
  };

  const allItems = options.map((label, index) => {
    return (
      <MenuItem key={index} value={label}>
        {label}
      </MenuItem>
    );
  });

  return (
    <FormControl sx={{ width: 100 }} size="small">
      <InputLabel id={'select-small'}>{label}</InputLabel>
      <Select
        labelId="select-small"
        id="select-small"
        value={value}
        label={label}
        onChange={handleChange}
        sx={{ backgroundColor: 'white' }}
      >
        {allItems}
      </Select>
    </FormControl>
  );
}
