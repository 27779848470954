import dayjs from 'dayjs';

export default function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function getPropByString(obj, propString) {
  if (!propString) return obj;

  var prop,
    props = propString.split('.');

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
}

export function getObjectSize(object) {
  const json = JSON.stringify(object);
  const bytes = new Blob([json]).size;
  const kiloBytes = Math.round((bytes / 1024) * 100) / 100; // arrondi à deux décimales
  return `${kiloBytes} Ko`;
}

export function capitalizeFirstLetter(str) {
  return str !== undefined && str !== null && str.length ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export function getFormatShortDateFr(dateFirestore) {
  const date = new Date(dateFirestore);
  const formattedDate = date.toLocaleDateString('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return formattedDate;
}

export function getFormatHourFr(currentDate) {
  const newCurrentDate = dayjs.tz(currentDate);
  return newCurrentDate.hour().toString().padStart(2, '0') + ':' + newCurrentDate.minute().toString().padStart(2, '0');
}

export function getDateHourFrFromTimestamp(timestamp) {
  const date = dayjs.tz(dayjs.unix(timestamp)).locale('fr');
  return date.format('DD/MM/YYYY à HH:MM');
}

export function getFormatLongDateUs(currentDate) {
  const newCurrentDate = dayjs.tz(currentDate);
  const year = newCurrentDate.year();
  const month = newCurrentDate.month().toString().padStart(2, '0');
  const day = newCurrentDate.day().toString().padStart(2, '0');
  return year + '-' + month + '-' + day;
}

export function getFormatLongDateFr(dateFirestore) {
  const date = dayjs.tz(dateFirestore);
  return capitalizeFirstLetter(date.locale('fr').format('dddd D MMMM YYYY'));
}

export function normalizePhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  if (phoneNumberString.startsWith('+33')) cleaned = '0' + cleaned.substring(2, 11);
  var match = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/);
  if (match) {
    return match[1] + '.' + match[2] + '.' + match[3] + '.' + match[4] + '.' + match[5];
  }
  return phoneNumberString;
}

export function formaterNombre(nombre) {
  return nombre !== undefined ? nombre.toFixed(2).replace('.', ',') : 'error format number';
}

export function formatEtage(num) {
  if (num === 0) {
    return 'RDC';
  } else if (num === 1) {
    return '1ère étage';
  } else if (num >= 2 && num <= 30) {
    return num + 'ème étage';
  } else {
    return "Numéro d'étage invalide";
  }
}

export function getGeo(address) {
  return address['latitude'] + ',' + address['longitude'];
}
