import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { Paper } from '@mui/material';
import { red, blue, green, yellow, deepPurple } from '@mui/material/colors';

const mainColor = '#1791D1';
//const mainColor = "#FFFF00";

const theme = createTheme({
  palette: {
    primary: {
      main: mainColor,
    },
    secondary: {
      main: '#ed6c02',
    },
    warning: {
      main: '#ed6c02',
    },
    submit: {
      main: red[500],
      contrastText: '#fff',
    },
    submitLight: {
      main: '#fff',
      contrastText: red[500],
    },
    submitDark: {
      main: red[900],
      contrastText: '#fff',
    },
    modified: {
      main: red[500],
      contrastText: '#fff',
    },
    modifiedDark: {
      main: red[900],
      contrastText: '#fff',
    },
    waiting: {
      main: yellow[900],
      contrastText: '#fff',
    },
    waitingLight: {
      main: '#fff',
      contrastText: yellow[900],
    },
    waitingDark: {
      main: 'rgb(197, 109, 0)',
      contrastText: '#fff',
    },
    assign: {
      main: blue[500],
      contrastText: '#fff',
    },
    assignLight: {
      main: '#fff',
      contrastText: blue[500],
    },
    assignDark: {
      main: blue[700],
      contrastText: '#fff',
    },
    control: {
      main: deepPurple[500],
      contrastText: '#fff',
    },
    controlLight: {
      main: '#fff',
      contrastText: deepPurple[500],
    },
    controlDark: {
      main: deepPurple[800],
      contrastText: '#fff',
    },
    finish: {
      main: green[500],
      contrastText: '#fff',
    },
    finishLight: {
      main: '#fff',
      contrastText: green[500],
    },
    finishDark: {
      main: green[700],
      contrastText: '#fff',
    },
  },

  components: {
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          margin: '10px',
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:first-of-type': {
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          },
          '&:last-of-type': {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          fontWeight: 'bold',
        },
        outlined: {
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          backgroundColor: 'white',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          backgroundColor: 'white',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#1791D1',
          th: {
            fontSize: '16px',
            fontWeight: 400,
            color: 'white',

            fontFamily: 'Roboto',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          transition: 'none',
          marginLeft: '12px',
          '&.Mui-selected': {
            backgroundColor: '#f5f5f5',
            color: '#1791D1',
            borderRadius: '30px 0px 0px 30px',
            '& .MuiListItemIcon-root': {
              color: '#1791D1',
            },
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#f5f5f5',
            color: '#1791D1',
            borderRadius: '30px 0px 0px 30px',
            '& .MuiListItemIcon-root': {
              color: '#1791D1',
            },
          },
          '&:hover': {
            backgroundColor: '#f5f5f5',
            color: '#1791D1',
            borderRadius: '30px 0px 0px 30px',
            '& .MuiListItemIcon-root': {
              color: '#1791D1',
            },
          },
        },
      },
    },
  },

  typography: {
    h5: {
      fontSize: 26,
      fontWeight: 'bold',
      fontFamily: 'Poppins',
    },
    h6: {
      fontSize: 16,
      fontFamily: 'Poppins',
    },
    body: {
      fontFamily: 'Poppins',
      fontSize: 16,
      color: '#707070',
    },
  },
});

export default theme;

export const getStylesPapers = (status) => {
  if (status === 'submit') return theme.palette.submit.main;
  if (status === 'waiting') return theme.palette.waiting.main;
  if (status === 'assign') return theme.palette.assign.main;
  if (status === 'control') return theme.palette.control.main;
  if (status === 'finish') return theme.palette.finish.main;

  return theme.palette.submit.main;
};

export const PaperViewEDL = styled(Paper)(({ color }) => {
  return {
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '24px',
    overflow: 'hidden',
    boxShadow: `0px 5px 10px ${color}80`,
    height: '100%',
  };
});

export const PaperMyEdls = styled(Paper)(({ color, clicked }) => {
  const commonStyle = {
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '24px',
    overflow: 'hidden',
    boxShadow: `0px 5px 10px ${color}90`,
    height: '100%',
    //transition: "background-color 0.3s ease-out",
    '& > .icon': {
      position: 'absolute',
      top: 5,
      right: 5,
      color: color,
    },
    '.main-title-card-provider': {
      fontWeight: 'bold',
      mb: 2,
      fontSize: '20px',
    },
    '.grid-title-card-provider': {
      width: '140px !important',
    },
    '.title-benef-card-provider': {
      color: theme.palette.primary.main,
    },
    '.item-benef-card-provider': {
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.title-card-provider-refuse': {
      color: theme.palette.error.main,
      fontWeight: 900,
    },
  };

  const pseudoHover = {
    '&:hover': {
      '.main-title-card-provider,.title-card-provider,.title-benef-card-provider,.item-benef-card-provider': {
        color: 'white',
      },
      '.title-card-provider-refuse': {
        color: theme.palette.error.main,
        fontWeight: 900,
      },
      backgroundColor: blue[300],
      color: 'white',
      cursor: 'pointer',
      '> .icon': {
        color: 'white',
      },
      '> .title-card': {
        color: 'white',
      },
      '> :last-child': {
        color: 'white',
      },
      hr: {
        borderColor: 'white',
      },
    },
  };

  const hover = {
    '.main-title-card-provider,.title-card-provider,.title-benef-card-provider,.item-benef-card-provider': {
      color: 'black',
    },
    '.title-card-provider-refuse': {
      color: theme.palette.error.main,
      fontWeight: 900,
    },
    backgroundColor: blue[200],
    color: 'black',
    cursor: 'pointer',
    '> .icon': {
      color: 'black',
    },
    '> .title-card': {
      color: 'black',
    },
    '> :last-child': {
      color: 'black',
    },
    hr: {
      borderColor: 'black',
    },
  };

  if (clicked === 'true') {
    return { ...commonStyle, ...hover };
  } else {
    return { ...commonStyle, ...pseudoHover };
  }
});
