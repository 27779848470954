import React from 'react';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

export default function Aside({ handlePageChange, handleUserLogout, menuItems }) {
  return (
    <Box sx={{ height: '100%' }}>
      <Box
        sx={{
          height: '50px',
          display: 'flex',
        }}
      >
        <Box m="auto">
          <Typography variant="h5">ADELE</Typography>
        </Box>
      </Box>

      <List>
        {Object.values(menuItems.app).map((x) => {
          return (
            <ListItem key={x.label} disablePadding>
              <ListItemButton selected={x.isShow} onClick={() => handlePageChange(x.label)}>
                <ListItemIcon
                  sx={{
                    color: 'white',
                  }}
                >
                  {x.icone}
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="h6">{x.label}</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Divider color="white" sx={{ marginX: 2 }} />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={handleUserLogout}>
            <ListItemIcon sx={{ color: 'white' }}>
              <LogoutIcon />
            </ListItemIcon>
            <Typography variant="h6">Se déconnecter</Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
