import React, { useRef, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import logo from '../../img/logo.png';
import avatar from '../../img/avatar.png';
import { useUserContext } from '../../contexts-api/AuthProvider';
import { useNavigate, Navigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

export function LinearIndeterminate() {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  );
}

export default function Login() {
  const navigate = useNavigate();
  const { currentUser, signin } = useUserContext();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const localProfil = useRef(null);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      await signin({
        email: data.get('email'),
        password: data.get('password'),
      }).then(() => {
        const profilJSON = localStorage.getItem('profil');
        localProfil.current = profilJSON !== 'undefined' && JSON.parse(profilJSON);
        navigate('/app');
      });
    } catch (err) {
      setError(err.code);
    } finally {
      setLoading(false);
    }
  };

  //Récupération du profil dans le localstorage
  //
  //

  return currentUser && localProfil.current ? (
    <Navigate to="/app" replace={true} />
  ) : (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={7}
        md={7}
        sx={{
          backgroundImage: `url(${logo})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'white',
          backgroundSize: '70%',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          marginTop={8}
          display={{
            xs: 'none',
            sm: 'block',
            md: 'block',
          }}
          sx={{ width: '100%', textAlign: 'center' }}
        >
          <Paper elevation={6}>
            <Typography
              variant="h5"
              component="h2"
              sx={{
                padding: 1,
                color: (t) => t.palette.primary.light,
              }}
            >
              Gestion des EDL
            </Typography>
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={12} sm={5} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar alt="avatar colibri" src={avatar} sx={{ width: 56, height: 56 }} />
          <Typography component="h1" variant="h5">
            Se connecter
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Votre adresse mail"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Typography
              variant="body"
              component="div"
              style={{
                color: 'red',
                fontSize: '0.8rem',
              }}
            >
              {error}
            </Typography>

            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Se souvenir de moi" />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Se connecter
            </Button>
            {loading && <LinearIndeterminate />}
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Mot de passe oublié ?
                </Link>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.skyrenov.fr">
          https://www.skyrenov.fr
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
}
