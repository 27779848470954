import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';

import {
  capitalizeFirstLetter,
  getFormatShortDateFr,
  normalizePhoneNumber,
  getFormatHourFr,
  formatEtage,
} from '../../../utils';
import './viewEDL.css';

import { PaperViewEDL } from '../../../Theme';

export default function InfosEDL({ datasEdl, color, typeCompany }) {
  const grades =
    datasEdl.grades === null || datasEdl.grades === '' ? 'Non renseignée' : datasEdl.grades.replaceAll('\n', '</br>');

  return (
    <PaperViewEDL color={color}>
      {typeCompany === 'customer' &&
        datasEdl?.metadatas?.status === 'assign' &&
        datasEdl?.metadatas?.isReportUpload && (
          <Typography
            variant="h1"
            color="error"
            sx={{
              fontWeight: 'bold',
              mb: 2,
              fontSize: '20px',
            }}
          >
            Cet état des lieux a été réalisé et est en cours de contrôle par nos services
          </Typography>
        )}
      <Typography
        variant="h6"
        color={color}
        sx={{
          fontWeight: 'bold',
          mb: 2,
          fontSize: '20px',
        }}
      >
        Informations
      </Typography>
      <Grid container spacing={0} xs={12}>
        <Grid container xs flexDirection="column">
          <Grid xs={12}>
            <Typography variant="body">
              Date {datasEdl?.metadatas?.status === 'assign' ? 'prévue' : 'souhaitée'}
            </Typography>
          </Grid>
          {datasEdl?.metadatas?.status === 'assign' && (
            <Grid xs={12}>
              <Typography variant="body">Heure prévue</Typography>
            </Grid>
          )}
          <Grid xs={12}>
            <Typography variant="body">EDL</Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="body">Adresse</Typography>
          </Grid>
          {datasEdl.possession.address.complement && (
            <Grid xs={12}>
              <Typography variant="body"> </Typography>
            </Grid>
          )}
          <Grid xs={12}>
            <Typography variant="body"> </Typography>
          </Grid>
          {datasEdl.possession?.numFloor >= 0 && (
            <Grid xs={12}>
              <Typography variant="body">Etage</Typography>
            </Grid>
          )}
          <Grid xs sx={{ my: 1 }}>
            <Divider />
          </Grid>
          <Grid xs={12}>
            <Typography variant="body">Priopriétaire</Typography>
          </Grid>
          <Grid xs sx={{ my: 1 }}>
            <Divider />
          </Grid>
          {datasEdl.tenants.map((tenants, idx) => {
            return (
              <Fragment key={idx}>
                {idx > 0 && (
                  <Grid xs={12}>
                    <Divider
                      sx={{
                        my: 1,
                      }}
                    />
                  </Grid>
                )}
                <Grid xs={12}>
                  <Typography variant="body">
                    {datasEdl.tenants.length > 1 ? 'Locataire n° ' + (idx + 1) : 'Locataire'}
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <Typography variant="body">Téléphone</Typography>
                </Grid>
                <Grid xs={12}>
                  <Typography variant="body">Email</Typography>
                </Grid>
              </Fragment>
            );
          })}
          <Grid xs sx={{ my: 1 }}>
            <Divider />
          </Grid>
        </Grid>

        <Grid container xs="auto" flexDirection="column">
          <Grid xs={12}>
            <Typography variant="body1" className="edlInfos-item">
              {getFormatShortDateFr(datasEdl?.manage?.dateEDL)}
            </Typography>
          </Grid>
          {datasEdl?.metadatas?.status === 'assign' && (
            <Grid xs={12}>
              <Typography variant="body1" className="edlInfos-item">
                {getFormatHourFr(datasEdl.manage.dateEDL)}
              </Typography>
            </Grid>
          )}
          <Grid>
            <Typography variant="body1" className="edlInfos-item">
              {capitalizeFirstLetter(datasEdl.manage.typeEDL)}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body1" className="edlInfos-item">
              {datasEdl.possession.address.street}
            </Typography>
          </Grid>
          {datasEdl.possession.address.complement && (
            <Grid>
              <Typography variant="body1" className="edlInfos-item">
                {datasEdl.possession.address.complement}
              </Typography>
            </Grid>
          )}
          <Grid>
            <Typography variant="body1" className="edlInfos-item">
              {datasEdl.possession.address.zipCode} {datasEdl.possession.address.locality}
            </Typography>
          </Grid>
          {datasEdl.possession?.numFloor >= 0 && (
            <Typography variant="body1" className="edlInfos-item">
              {formatEtage(datasEdl.possession.numFloor)}
            </Typography>
          )}
          <Grid xs sx={{ my: 1 }}>
            <Divider />
          </Grid>
          <Grid xs={12}>
            <Typography variant="body1">{datasEdl?.possession?.nameOwner}</Typography>
          </Grid>
          <Grid xs sx={{ my: 1 }}>
            <Divider />
          </Grid>
          {datasEdl.tenants.map((tenants, idx) => {
            return (
              <Fragment key={idx}>
                {idx > 0 && (
                  <Grid xs={12}>
                    <Divider
                      sx={{
                        my: 1,
                      }}
                    />
                  </Grid>
                )}
                <Grid xs={12}>
                  <Typography variant="body1" className="edlInfos-item">
                    {datasEdl.tenants[idx].civility} {datasEdl.tenants[idx].surname} {datasEdl.tenants[idx].name}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="body1" className="edlInfos-item">
                    {normalizePhoneNumber(datasEdl.tenants[idx].phone)}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="body1" className="edlInfos-item">
                    {datasEdl.tenants[idx].email === null ? 'Non renseignée' : datasEdl.tenants[idx].email}
                  </Typography>
                </Grid>
              </Fragment>
            );
          })}
          <Grid xs sx={{ my: 1 }}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>

      <Grid container width="100%" flexDirection="column" spacing={1}>
        <Grid xs>
          <Typography variant="span" color={color}>
            Notes
          </Typography>
        </Grid>
        <Grid xs>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: grades,
            }}
          ></Typography>
        </Grid>
      </Grid>
    </PaperViewEDL>
  );
}
