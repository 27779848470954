import * as React from 'react';
import { Typography, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { capitalizeFirstLetter, formaterNombre } from '../../../utils';
import Divider from '@mui/material/Divider';
import { PaperViewEDL } from '../../../Theme';
import { getGeo } from '../../../utils';
import { useTheme } from '@mui/material/styles';
import HtmlTooltip from './components/HtmlTooltip';

export default function PricingEDLAssignProvider({ datasEdl, color }) {
  const theme = useTheme();

  const customerGeo = getGeo(datasEdl.customer.company.address.geopoint);
  const providerGeo = getGeo(datasEdl.provider.company.address.geopoint);
  const edlGeo = getGeo(datasEdl.possession.address.geopoint);

  function getListItems(nameAnnexes, label) {
    if (nameAnnexes === undefined) return;
    return nameAnnexes.map((item, idx) => (
      <div style={{ marginLeft: '-110px' }} key={idx}>
        <Typography variant="body" sx={{ fontSize: '12px' }}>
          {item.num === null ? label + (idx + 1) + ' : non indiqué' : label + (idx + 1) + ' : ' + item.num}
        </Typography>
      </div>
    ));
  }

  const listItemsCaves = getListItems(datasEdl.possession.annexes?.caves, 'Cave n° ');
  const listItemsParkings = getListItems(datasEdl.possession.annexes?.parkings, 'Parking n° ');

  return (
    <PaperViewEDL
      color={color}
      sx={{
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: 2,
        overflow: 'hidden',
      }}
    >
      <Grid container spacing={0}>
        <Grid container width="100%">
          <Grid>
            <Typography
              variant="h6"
              color={color}
              sx={{
                fontWeight: 'bold',
                mb: 2,
                fontSize: '20px',
              }}
            >
              Tarification
            </Typography>
          </Grid>
          <Grid xs>
            <Typography
              variant="h6"
              color={color}
              sx={{
                fontWeight: 'bold',
                mb: 2,
                fontSize: '20px',
              }}
            ></Typography>
          </Grid>
          <Grid xs>
            <Typography
              variant="h6"
              color="primary"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                fontSize: '20px',
              }}
              textAlign={'end'}
            ></Typography>
          </Grid>
        </Grid>

        <Grid container width="100%">
          <Grid xs="auto" className="edlInfos-title">
            <Typography variant="body"></Typography>
          </Grid>
          <Grid xs>
            <Typography variant="body1" className="edlInfos-item" textAlign="end"></Typography>
          </Grid>
          <Grid xs>
            <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
              Client
            </Typography>
          </Grid>
          <Grid xs>
            <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
              Agent
            </Typography>
          </Grid>
          <Grid xs>
            <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
              Sky Renov
            </Typography>
          </Grid>
        </Grid>

        <Grid container width="100%">
          <Grid xs="auto" className="edlInfos-title">
            <Typography variant="body">Logement</Typography>
          </Grid>
          <Grid xs>
            <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
              {capitalizeFirstLetter(datasEdl.possession.typeHouse)} {datasEdl.possession.nbRoom}
            </Typography>
          </Grid>
          <Grid xs>
            <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
              {formaterNombre(datasEdl['customer'].pricing.tarif.base)} €
            </Typography>
          </Grid>
          <Grid xs>
            <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
              {formaterNombre(datasEdl['provider'].pricing.tarif.base)} €
            </Typography>
          </Grid>
          <Grid xs>
            <Typography
              variant="body1"
              className="edlInfos-item"
              textAlign={'end'}
              fontWeight="bold"
              sx={{
                color:
                  datasEdl['customer'].pricing.tarif.base - datasEdl['provider'].pricing.tarif.base > 0
                    ? theme.palette.success.main
                    : datasEdl['customer'].pricing.tarif.base - datasEdl['provider'].pricing.tarif.base === 0
                      ? 'black'
                      : theme.palette.error.main,
              }}
            >
              {formaterNombre(datasEdl['customer'].pricing.tarif.base - datasEdl['provider'].pricing.tarif.base)} €
            </Typography>
          </Grid>
        </Grid>
        <Grid container width="100%">
          <Grid xs="auto" className="edlInfos-title">
            <Typography variant="body">Distance</Typography>
          </Grid>
          <Grid xs>
            <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
              <Link
                href={`https://www.google.com/maps/dir/?api=1&origin=${customerGeo}&destination=${edlGeo}&travelmode=driving&dirflg=d`}
                target="_blank"
                rel="noopener"
                underline="hover"
                sx={{
                  color: color,
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontFamily: 'Poppins',
                  }}
                >
                  {datasEdl['customer'].pricing.deplacement.km.toFixed(2).replace('.', ',')}
                </Typography>
              </Link>
              {' - '}
              <Link
                href={`https://www.google.com/maps/dir/?api=1&origin=${providerGeo}&destination=${edlGeo}&travelmode=driving&dirflg=d`}
                target="_blank"
                rel="noopener"
                underline="hover"
                sx={{
                  color: color,
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontFamily: 'Poppins',
                  }}
                >
                  {datasEdl['provider'].pricing.deplacement.km.toFixed(2).replace('.', ',')}
                </Typography>
              </Link>
              {' km'}
            </Typography>
          </Grid>

          <Grid xs>
            {(datasEdl['customer'].pricing.tarif.deplacement > 0 && (
              <Typography
                variant="body1"
                className="edlInfos-item"
                textAlign={'end'}
                sx={{
                  color: theme.palette.warning.main,
                }}
              >
                <HtmlTooltip
                  placement="left-end"
                  arrow
                  onMouseEnter={(e) => (e.target.style.cursor = 'pointer')}
                  title={
                    <React.Fragment>
                      <Typography width="250px">Frais de déplacement</Typography>

                      <Grid container justifyContent="space-between">
                        <Grid>Mode :</Grid>
                        <Grid>{datasEdl['customer'].pricing.deplacement.mode}</Grid>
                      </Grid>
                      <Grid container justifyContent="space-between">
                        <Grid>Kilométrage maximun :</Grid>
                        <Grid>{datasEdl['customer'].pricing.deplacement.maxBorne.toFixed(2).replace('.', ',')} km</Grid>
                      </Grid>
                      <Grid container justifyContent="space-between">
                        <Grid> Kilométrage relevé :</Grid>
                        <Grid>{datasEdl['customer'].pricing.deplacement.km.toFixed(2).replace('.', ',')} km</Grid>
                      </Grid>
                      <Divider
                        sx={{
                          backgroundColor: 'white',
                          m: 1,
                        }}
                      />
                      <Grid container justifyContent="space-between">
                        <Grid>Majoration :</Grid>
                        <Grid>{datasEdl['customer'].pricing.deplacement.majoTarif} €</Grid>
                      </Grid>
                    </React.Fragment>
                  }
                >
                  <span>{formaterNombre(datasEdl['customer'].pricing.tarif.deplacement)} €</span>
                </HtmlTooltip>
              </Typography>
            )) || (
              <Typography
                variant="body1"
                className="edlInfos-item"
                textAlign={'end'}
                sx={{
                  color: 'black',
                }}
              >
                {formaterNombre(datasEdl['customer'].pricing.tarif.deplacement)} €
              </Typography>
            )}
          </Grid>

          <Grid xs>
            {(datasEdl['provider'].pricing.tarif.deplacement > 0 && (
              <Typography
                variant="body1"
                className="edlInfos-item"
                textAlign={'end'}
                sx={{
                  color: theme.palette.warning.main,
                }}
              >
                <HtmlTooltip
                  placement="left-end"
                  arrow
                  onMouseEnter={(e) => (e.target.style.cursor = 'pointer')}
                  title={
                    <React.Fragment>
                      <Typography width="250px">Frais de déplacement</Typography>

                      <Grid container justifyContent="space-between">
                        <Grid>Mode :</Grid>
                        <Grid>{datasEdl['provider'].pricing.deplacement.mode}</Grid>
                      </Grid>
                      <Grid container justifyContent="space-between">
                        <Grid>Kilométrage maximun :</Grid>
                        <Grid>{datasEdl['provider'].pricing.deplacement.maxBorne.toFixed(2).replace('.', ',')} km</Grid>
                      </Grid>
                      <Grid container justifyContent="space-between">
                        <Grid> Kilométrage relevé :</Grid>
                        <Grid>{datasEdl['provider'].pricing.deplacement.km.toFixed(2).replace('.', ',')} km</Grid>
                      </Grid>
                      <Divider
                        sx={{
                          backgroundColor: 'white',
                          m: 1,
                        }}
                      />
                      <Grid container justifyContent="space-between">
                        <Grid>Majoration :</Grid>
                        <Grid>{datasEdl['provider'].pricing.deplacement.majoTarif} €</Grid>
                      </Grid>
                    </React.Fragment>
                  }
                >
                  <span>{formaterNombre(datasEdl['provider'].pricing.tarif.deplacement)} €</span>
                </HtmlTooltip>
              </Typography>
            )) || (
              <Typography
                variant="body1"
                className="edlInfos-item"
                textAlign={'end'}
                sx={{
                  color: 'black',
                }}
              >
                {formaterNombre(datasEdl['provider'].pricing.tarif.deplacement)} €
              </Typography>
            )}
          </Grid>

          <Grid xs>
            <Typography
              variant="body1"
              className="edlInfos-item"
              textAlign={'end'}
              fontWeight="bold"
              sx={{
                color:
                  datasEdl['customer'].pricing.tarif.deplacement - datasEdl['provider'].pricing.tarif.deplacement > 0
                    ? theme.palette.success.main
                    : datasEdl['customer'].pricing.tarif.deplacement -
                          datasEdl['provider'].pricing.tarif.deplacement ===
                        0
                      ? 'black'
                      : theme.palette.error.main,
              }}
            >
              {formaterNombre(
                datasEdl['customer'].pricing.tarif.deplacement - datasEdl['provider'].pricing.tarif.deplacement,
              )}{' '}
              €
            </Typography>
          </Grid>
        </Grid>

        {datasEdl.possession.typeHouse !== 'parking' && (
          <>
            <Grid container width="100%">
              <Grid xs="auto" className="edlInfos-title">
                <Typography variant="body">Bien</Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                  {capitalizeFirstLetter(datasEdl.possession.typeRent)}
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                  {formaterNombre(datasEdl['customer'].pricing.tarif.meuble)} €
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                  {formaterNombre(datasEdl['provider'].pricing.tarif.meuble)} €
                </Typography>
              </Grid>
              <Grid xs>
                <Typography
                  variant="body1"
                  className="edlInfos-item"
                  textAlign={'end'}
                  fontWeight="bold"
                  sx={{
                    color:
                      datasEdl['customer'].pricing.tarif.meuble - datasEdl['provider'].pricing.tarif.meuble > 0
                        ? theme.palette.success.main
                        : datasEdl['customer'].pricing.tarif.meuble - datasEdl['provider'].pricing.tarif.meuble === 0
                          ? 'black'
                          : theme.palette.error.main,
                  }}
                >
                  {formaterNombre(
                    datasEdl['customer'].pricing.tarif.meuble - datasEdl['provider'].pricing.tarif.meuble,
                  )}{' '}
                  €
                </Typography>
              </Grid>
            </Grid>
            <Grid container width="100%">
              <Grid xs="auto" className="edlInfos-title">
                <Typography variant="body">Superficie</Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                  {datasEdl.possession.area} m²
                </Typography>
              </Grid>
              <Grid xs>
                {(datasEdl['customer'].pricing.tarif.superficie > 0 && (
                  <Typography
                    variant="body1"
                    className="edlInfos-item"
                    textAlign={'end'}
                    sx={{
                      color: theme.palette.warning.main,
                    }}
                  >
                    <HtmlTooltip
                      placement="left-end"
                      arrow
                      onMouseEnter={(e) => (e.target.style.cursor = 'pointer')}
                      title={
                        <React.Fragment>
                          <Typography width="250px">Frais supplémentaires</Typography>

                          <Grid container justifyContent="space-between">
                            <Grid>Nb de pièces :</Grid>
                            <Grid>{datasEdl['customer'].pricing.nbRoom}</Grid>
                          </Grid>
                          <Grid container justifyContent="space-between">
                            <Grid>Superficie maximun :</Grid>
                            <Grid>{datasEdl['customer'].pricing.maxSuperficie} m²</Grid>
                          </Grid>
                          <Grid container justifyContent="space-between">
                            <Grid>Superficie renseignée :</Grid>
                            <Grid>{datasEdl['customer'].pricing.superficie} m²</Grid>
                          </Grid>
                          <Grid container justifyContent="space-between">
                            <Grid>Superficie excédentaire :</Grid>
                            <Grid> {datasEdl['customer'].pricing.supSuperficie} m²</Grid>
                          </Grid>
                          <Grid container justifyContent="space-between">
                            <Grid>Prix par m² excédentaire :</Grid>
                            <Grid>{formaterNombre(datasEdl['customer'].pricing.priceBySupMeters)} €</Grid>
                          </Grid>
                          <Divider
                            sx={{
                              backgroundColor: 'white',
                              m: 1,
                            }}
                          />
                          <Grid container justifyContent="space-between">
                            <Grid>Majoration :</Grid>
                            <Grid>{formaterNombre(datasEdl['customer'].pricing.tarif.superficie)} €</Grid>
                          </Grid>
                        </React.Fragment>
                      }
                    >
                      <span>{formaterNombre(datasEdl['customer'].pricing.tarif.superficie)} €</span>
                    </HtmlTooltip>
                  </Typography>
                )) || (
                  <Typography
                    variant="body1"
                    className="edlInfos-item"
                    textAlign={'end'}
                    sx={{
                      color: 'black',
                    }}
                  >
                    {formaterNombre(datasEdl['customer'].pricing.tarif.superficie)} €
                  </Typography>
                )}
              </Grid>
              <Grid xs>
                {(datasEdl['provider'].pricing.tarif.superficie > 0 && (
                  <Typography
                    variant="body1"
                    className="edlInfos-item"
                    textAlign={'end'}
                    sx={{
                      color: theme.palette.warning.main,
                    }}
                  >
                    <HtmlTooltip
                      placement="left-end"
                      arrow
                      onMouseEnter={(e) => (e.target.style.cursor = 'pointer')}
                      title={
                        <React.Fragment>
                          <Typography width="250px">Frais supplémentaires</Typography>

                          <Grid container justifyContent="space-between">
                            <Grid>Nb de pièces :</Grid>
                            <Grid>{datasEdl['provider'].pricing.nbRoom}</Grid>
                          </Grid>
                          <Grid container justifyContent="space-between">
                            <Grid>Superficie maximun :</Grid>
                            <Grid>{datasEdl['provider'].pricing.maxSuperficie} m²</Grid>
                          </Grid>
                          <Grid container justifyContent="space-between">
                            <Grid>Superficie renseignée :</Grid>
                            <Grid>{datasEdl['provider'].pricing.superficie} m²</Grid>
                          </Grid>
                          <Grid container justifyContent="space-between">
                            <Grid>Superficie excédentaire :</Grid>
                            <Grid> {datasEdl['provider'].pricing.supSuperficie} m²</Grid>
                          </Grid>
                          <Grid container justifyContent="space-between">
                            <Grid>Prix par m² excédentaire :</Grid>
                            <Grid>{formaterNombre(datasEdl['provider'].pricing.priceBySupMeters)} €</Grid>
                          </Grid>
                          <Divider
                            sx={{
                              backgroundColor: 'white',
                              m: 1,
                            }}
                          />
                          <Grid container justifyContent="space-between">
                            <Grid>Majoration :</Grid>
                            <Grid>{formaterNombre(datasEdl['provider'].pricing.tarif.superficie)} €</Grid>
                          </Grid>
                        </React.Fragment>
                      }
                    >
                      <span>{formaterNombre(datasEdl['provider'].pricing.tarif.superficie)} €</span>
                    </HtmlTooltip>
                  </Typography>
                )) || (
                  <Typography
                    variant="body1"
                    className="edlInfos-item"
                    textAlign={'end'}
                    sx={{
                      color: 'black',
                    }}
                  >
                    {formaterNombre(datasEdl['provider'].pricing.tarif.superficie)} €
                  </Typography>
                )}
              </Grid>
              <Grid xs>
                <Typography
                  variant="body1"
                  className="edlInfos-item"
                  textAlign={'end'}
                  fontWeight="bold"
                  sx={{
                    color:
                      datasEdl['customer'].pricing.tarif.superficie - datasEdl['provider'].pricing.tarif.superficie > 0
                        ? theme.palette.success.main
                        : datasEdl['customer'].pricing.tarif.superficie -
                              datasEdl['provider'].pricing.tarif.superficie ===
                            0
                          ? 'black'
                          : theme.palette.error.main,
                  }}
                >
                  {formaterNombre(
                    datasEdl['customer'].pricing.tarif.superficie - datasEdl['provider'].pricing.tarif.superficie,
                  )}{' '}
                  €
                </Typography>
              </Grid>
            </Grid>
            <Grid container width="100%">
              <Grid xs="auto" className="edlInfos-title">
                <Typography variant="body">Vidéo</Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                  {datasEdl.manage.video}
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                  {formaterNombre(datasEdl['customer'].pricing.tarif.video)} €
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                  {formaterNombre(datasEdl['provider'].pricing.tarif.video)} €
                </Typography>
              </Grid>
              <Grid xs>
                <Typography
                  variant="body1"
                  className="edlInfos-item"
                  textAlign={'end'}
                  fontWeight="bold"
                  sx={{
                    color:
                      datasEdl['customer'].pricing.tarif.video - datasEdl['provider'].pricing.tarif.video > 0
                        ? theme.palette.success.main
                        : datasEdl['customer'].pricing.tarif.video - datasEdl['provider'].pricing.tarif.video === 0
                          ? 'black'
                          : theme.palette.error.main,
                  }}
                >
                  {formaterNombre(datasEdl['customer'].pricing.tarif.video - datasEdl['provider'].pricing.tarif.video)}{' '}
                  €
                </Typography>
              </Grid>
            </Grid>
            <Grid xs sx={{ m: 1 }}>
              <Divider />
            </Grid>
            <Grid container width="100%">
              <Typography variant="span" color={color} sx={{ mb: 1 }}>
                Annexes
              </Typography>
            </Grid>
            {datasEdl.possession.annexes.nbCaves > 0 && (
              <Grid container xs={12} width="100%">
                <Grid xs="auto" className="edlInfos-title">
                  <Typography variant="body">Caves</Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" textAlign={'end'}>
                    {datasEdl.possession.annexes.nbCaves}
                  </Typography>
                  {listItemsCaves}
                </Grid>
                <Grid xs>
                  <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                    {formaterNombre(datasEdl['customer'].pricing.tarif.caves)} €
                  </Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                    {formaterNombre(datasEdl['provider'].pricing.tarif.caves)} €
                  </Typography>
                </Grid>
                <Grid xs>
                  <Typography
                    variant="body1"
                    className="edlInfos-item"
                    textAlign={'end'}
                    fontWeight="bold"
                    sx={{
                      color:
                        datasEdl['customer'].pricing.tarif.caves - datasEdl['provider'].pricing.tarif.caves > 0
                          ? theme.palette.success.main
                          : datasEdl['customer'].pricing.tarif.caves - datasEdl['provider'].pricing.tarif.caves === 0
                            ? 'black'
                            : theme.palette.error.main,
                    }}
                  >
                    {formaterNombre(
                      datasEdl['customer'].pricing.tarif.caves - datasEdl['provider'].pricing.tarif.caves,
                    )}{' '}
                    €
                  </Typography>
                </Grid>
              </Grid>
            )}
            {datasEdl.possession.annexes.nbParkings > 0 && (
              <Grid container xs={12}>
                <Grid xs="auto" className="edlInfos-title">
                  <Typography variant="body">Parkings</Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" textAlign={'end'}>
                    {datasEdl.possession.annexes.nbParkings}
                  </Typography>
                  {listItemsParkings}
                </Grid>
                <Grid xs>
                  <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                    {formaterNombre(datasEdl['customer'].pricing.tarif.parkings)} €
                  </Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                    {formaterNombre(datasEdl['provider'].pricing.tarif.parkings)} €
                  </Typography>
                </Grid>
                <Grid xs>
                  <Typography
                    variant="body1"
                    className="edlInfos-item"
                    textAlign={'end'}
                    fontWeight="bold"
                    sx={{
                      color:
                        datasEdl['customer'].pricing.tarif.parkings - datasEdl['provider'].pricing.tarif.parkings > 0
                          ? theme.palette.success.main
                          : datasEdl['customer'].pricing.tarif.parkings -
                                datasEdl['provider'].pricing.tarif.parkings ===
                              0
                            ? 'black'
                            : theme.palette.error.main,
                    }}
                  >
                    {formaterNombre(
                      datasEdl['customer'].pricing.tarif.parkings - datasEdl['provider'].pricing.tarif.parkings,
                    )}{' '}
                    €
                  </Typography>
                </Grid>
              </Grid>
            )}
            {datasEdl.possession.annexes.nbBalcony > 0 && (
              <Grid container xs={12}>
                <Grid xs="auto" className="edlInfos-title">
                  <Typography variant="body">Balcons</Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" textAlign={'end'}>
                    {datasEdl.possession.annexes.nbBalcony}
                  </Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                    {formaterNombre(datasEdl['customer'].pricing.tarif.balcony)} €
                  </Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                    {formaterNombre(datasEdl['provider'].pricing.tarif.balcony)} €
                  </Typography>
                </Grid>
                <Grid xs>
                  <Typography
                    variant="body1"
                    className="edlInfos-item"
                    textAlign={'end'}
                    fontWeight="bold"
                    sx={{
                      color:
                        datasEdl['customer'].pricing.tarif.balcony - datasEdl['provider'].pricing.tarif.balcony > 0
                          ? theme.palette.success.main
                          : datasEdl['customer'].pricing.tarif.balcony - datasEdl['provider'].pricing.tarif.balcony ===
                              0
                            ? 'black'
                            : theme.palette.error.main,
                    }}
                  >
                    {formaterNombre(
                      datasEdl['customer'].pricing.tarif.balcony - datasEdl['provider'].pricing.tarif.balcony,
                    )}{' '}
                    €
                  </Typography>
                </Grid>
              </Grid>
            )}
            {datasEdl.possession.annexes.nbGarden > 0 && (
              <Grid container xs={12}>
                <Grid xs="auto" className="edlInfos-title">
                  <Typography variant="body">Jardins</Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" textAlign={'end'}>
                    {datasEdl.possession.annexes.nbGarden}
                  </Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                    {formaterNombre(datasEdl['customer'].pricing.tarif.garden)} €
                  </Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                    {formaterNombre(datasEdl['provider'].pricing.tarif.garden)} €
                  </Typography>
                </Grid>
                <Grid xs>
                  <Typography
                    variant="body1"
                    className="edlInfos-item"
                    textAlign={'end'}
                    fontWeight="bold"
                    sx={{
                      color:
                        datasEdl['customer'].pricing.tarif.garden - datasEdl['provider'].pricing.tarif.garden > 0
                          ? theme.palette.success.main
                          : datasEdl['customer'].pricing.tarif.garden - datasEdl['provider'].pricing.tarif.garden === 0
                            ? 'black'
                            : theme.palette.error.main,
                    }}
                  >
                    {formaterNombre(
                      datasEdl['customer'].pricing.tarif.garden - datasEdl['provider'].pricing.tarif.garden,
                    )}{' '}
                    €
                  </Typography>
                </Grid>
              </Grid>
            )}
            {datasEdl.possession.annexes.nbOther > 0 && (
              <Grid container xs={12}>
                <Grid xs="auto" className="edlInfos-title">
                  <Typography variant="body">Autres</Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" textAlign={'end'}>
                    {datasEdl.possession.annexes.nbOther}
                  </Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                    {formaterNombre(datasEdl['customer'].pricing.tarif.other)} €
                  </Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                    {formaterNombre(datasEdl['provider'].pricing.tarif.other)} €
                  </Typography>
                </Grid>
                <Grid xs>
                  <Typography
                    variant="body1"
                    className="edlInfos-item"
                    textAlign={'end'}
                    fontWeight="bold"
                    sx={{
                      color:
                        datasEdl['customer'].pricing.tarif.other - datasEdl['provider'].pricing.tarif.other > 0
                          ? theme.palette.success.main
                          : datasEdl['customer'].pricing.tarif.other - datasEdl['provider'].pricing.tarif.other === 0
                            ? 'black'
                            : theme.palette.error.main,
                    }}
                  >
                    {formaterNombre(
                      datasEdl['customer'].pricing.tarif.other - datasEdl['provider'].pricing.tarif.other,
                    )}{' '}
                    €
                  </Typography>
                </Grid>
              </Grid>
            )}

            {datasEdl.possession.annexes.nbCaves === 0 &&
              datasEdl.possession.annexes.nbParkings === 0 &&
              datasEdl.possession.annexes.nbBalcony === 0 &&
              datasEdl.possession.annexes.nbGarden === 0 &&
              datasEdl.possession.annexes.nbOther === 0 && (
                <Grid container xs={12}>
                  <Grid xs="auto">
                    <Typography variant="body1">Aucune annexe</Typography>
                  </Grid>
                  <Grid xs>
                    <Typography variant="body1" textAlign={'end'}></Typography>
                  </Grid>
                </Grid>
              )}

            <Grid xs sx={{ m: 1 }}>
              <Divider />
            </Grid>

            <Grid container width="100%">
              <Typography
                variant="h6"
                color={color}
                sx={{
                  mb: 1,
                  fontWeight: 'bold',
                }}
              >
                Total
              </Typography>
            </Grid>

            <Grid container width="100%">
              <Grid xs="auto" className="edlInfos-title">
                <Typography variant="body">Montant</Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold"></Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
                  {formaterNombre(datasEdl['customer'].pricing.tarif.total)} €
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
                  {formaterNombre(datasEdl['provider'].pricing.tarif.total)} €
                </Typography>
              </Grid>
              <Grid xs>
                <Typography
                  variant="body1"
                  className="edlInfos-item"
                  textAlign="end"
                  fontWeight="bold"
                  sx={{
                    color:
                      datasEdl['customer'].pricing.tarif.total - datasEdl['provider'].pricing.tarif.total > 0
                        ? theme.palette.success.main
                        : datasEdl['customer'].pricing.tarif.total - datasEdl['provider'].pricing.tarif.total === 0
                          ? 'black'
                          : theme.palette.error.main,
                  }}
                >
                  {formaterNombre(datasEdl['customer'].pricing.tarif.total - datasEdl['provider'].pricing.tarif.total)}{' '}
                  €
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </PaperViewEDL>
  );
}
