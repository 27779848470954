import React, { Fragment, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import ButtonGroupSky from '../atom/ButtonGroupSky';
import TextFieldSky from '../atom/TextFieldSky';

import { Typography } from '@mui/material';

//import SelectSky from "./../atom/SelectSky";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
//import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AlertSky from '../../../../components/AlertSky';

const Tenants = ({ name, defaultMaxTenants, value, formik, formIsSubmit }) => {
  const arrayTenants = value;
  const [countAlert, setCountAlert] = useState(0);

  const handleAddTenant = () => {
    if (arrayTenants.length < defaultMaxTenants) {
      let newTenants = [
        ...arrayTenants,
        {
          civility: 'Mme',
          name: null,
          surname: null,
          email: null,
          phone: null,
        },
      ];
      formik.setFieldValue(name, newTenants, formIsSubmit);
    } else {
      setCountAlert(countAlert + 1);
    }
  };

  const handleRemoveTenant = (idx) => {
    const newArrayTenants = [...arrayTenants];
    newArrayTenants.splice(idx, 1);
    formik.setFieldValue(name, newArrayTenants, false);
  };

  return (
    <Fragment>
      {countAlert > 0 && (
        <AlertSky countAlert={countAlert} severity="error" message="nombre maximun de locataires atteint" />
      )}
      <Grid xs={12} sx={{ marginBottom: { xs: '-21px', md: '-13px' } }}>
        <Typography variant="body">
          {arrayTenants.length === 1 ? 'Informations du locataire' : 'Informations des locataires'}
        </Typography>
      </Grid>
      <Grid container spacing={1} ml={{ xs: 0, md: 1 }} mt={2} disableEqualOverflow>
        {arrayTenants?.length > 0 &&
          arrayTenants?.map((item, idx) => (
            <Fragment key={idx}>
              <Grid xs={12} md="auto">
                <ButtonGroupSky
                  name={`tenants.${idx}.civility`}
                  arrayValues={['Mme', 'M.']}
                  formik={formik}
                  value={item.civility}
                />
              </Grid>

              <Grid xs={12} md="auto">
                <TextFieldSky
                  name={`tenants.${idx}.name`}
                  label="Prénom"
                  sx={{
                    minWidth: '250px',
                  }}
                  inputProps={{
                    maxLength: 41,
                  }}
                  formik={formik}
                  value={item.name}
                  formIsSubmit={formIsSubmit}
                />
              </Grid>
              <Grid xs={12} md="auto">
                <TextFieldSky
                  required
                  name={`tenants.${idx}.surname`}
                  label="Nom"
                  sx={{
                    minWidth: '200px',
                  }}
                  inputProps={{
                    maxLength: 41,
                  }}
                  formik={formik}
                  value={item.surname}
                  formIsSubmit={formIsSubmit}
                />
              </Grid>
              <Grid xs={12} md="auto">
                <TextFieldSky
                  name={`tenants.${idx}.email`}
                  label="E-mail"
                  sx={{
                    minWidth: '250px',
                  }}
                  inputProps={{
                    type: 'email',
                  }}
                  formik={formik}
                  value={item.email}
                  formIsSubmit={formIsSubmit}
                />
              </Grid>
              <Grid xs={12} md="auto">
                <TextFieldSky
                  required
                  name={`tenants.${idx}.phone`}
                  label="Téléphone"
                  sx={{
                    minWidth: '200px',
                  }}
                  inputProps={{
                    maxLength: 13,
                    type: 'tel',
                  }}
                  formik={formik}
                  value={item.phone}
                  formIsSubmit={formIsSubmit}
                />
              </Grid>
              {arrayTenants?.length > 1 && (
                <Grid xs="auto" padding={0} margin={0.5}>
                  <IconButton aria-label="delete" size="large" color="error" onClick={() => handleRemoveTenant(idx)}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Grid>
              )}
              <div
                style={{
                  width: '100%',
                }}
              ></div>
            </Fragment>
          ))}
      </Grid>
      <Grid xs={12}>
        <Button size="small" variant="outlined" onClick={handleAddTenant}>
          Ajouter un Locataire
        </Button>
      </Grid>
    </Fragment>
  );
};

export default Tenants;
