import { Navigate, useLocation } from 'react-router-dom';
import { useUserContext } from '../../contexts-api/AuthProvider';

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useUserContext();
  const location = useLocation();

  //tester si le jeton possède une claim "admin"
  if (!currentUser) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }
  return children;
};

export default ProtectedRoute;
