import React, { useEffect, useRef } from 'react';
import Collapse from '@mui/material/Collapse';
import { debounce } from '@mui/material/utils';

function GoogleMap({ center, zoom }) {
  const [checked, setChecked] = React.useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const map = new window.google.maps.Map(containerRef.current, {
      zoom: parseInt(zoom),
      center: { lat: center.latitude, lng: center.longitude },
    });
    new window.google.maps.Marker({
      position: { lat: center.latitude, lng: center.longitude },
      map: map,
    });
    map.setOptions({ gestureHandling: 'none' });
    map.addListener(
      'idle',
      debounce(function () {
        setChecked(true);
      }),
    );
  }, [center, zoom]);

  return (
    <Collapse in={checked}>
      <div id="map" ref={containerRef} />
    </Collapse>
  );
}

export default GoogleMap;
