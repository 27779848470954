import React, { Fragment, useMemo } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TextFieldSky from '../atom/TextFieldSky';
import { getPropByString } from '../../../../../utils';
import SelectSky from '../atom/SelectSky';

const AnnexesDyn = ({ name, label, options, value, formik, formIsSubmit }) => {
  const array = getPropByString(formik.values, name);

  const handleAnnexes = (data) => {
    const key = Object.keys(data)[0];
    const newNbAnnexes = data[key];

    const deltaAnnexes = newNbAnnexes - array.length;

    if (deltaAnnexes < 0) {
      if (newNbAnnexes === 0) {
        formik.setFieldValue(name, [], false);
      } else {
        const updatedList = [...getPropByString(formik.values, name)];
        updatedList.splice(deltaAnnexes);
        formik.setFieldValue(name, updatedList, false);
      }
    }
    if (deltaAnnexes > 0) {
      let arrayAnnexes = [];
      for (let i = 0; i < deltaAnnexes; i++) {
        arrayAnnexes = arrayAnnexes.concat([
          {
            num: '',
          },
        ]);
      }
      formik.setFieldValue(name, [...getPropByString(formik.values, name), ...arrayAnnexes], false);
    }
  };

  const nameSelect = useMemo(() => {
    let newNameSelect = '';
    if (name.split('.').length) {
      const arrayName = name.split('.');
      const nameSelect = arrayName.pop();
      newNameSelect = arrayName.join('.') + '.nb' + nameSelect.replace(/^./, nameSelect[0].toUpperCase());
    } else {
      newNameSelect = 'nb' + name.replace(/^./, name[0].toUpperCase());
    }

    return newNameSelect;
  }, [name]);

  return (
    <Grid container xs="auto" sm="auto" md="auto" flexDirection="column">
      <Grid xs>
        <SelectSky
          name={nameSelect}
          label={label}
          options={options}
          value={value}
          formik={formik}
          onChange={handleAnnexes}
        />
      </Grid>
      {array?.length > 0 &&
        array.map((item, idx) => (
          <Fragment key={idx}>
            <Grid xs={12} md="auto" marginTop={1}>
              <TextFieldSky
                name={`${name}.${idx}.num`}
                label="Numéro"
                value={item.num}
                formik={formik}
                sx={{ width: 100 }}
                InputLabelProps={{
                  shrink: true,
                }}
                formIsSubmit={formIsSubmit}
              />
            </Grid>
          </Fragment>
        ))}
    </Grid>
  );
};

export default AnnexesDyn;
