import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getListSociety } from '../../api-service-common';

export default function InputListSociety({ setNameCompany }) {
  const [value, setValue] = useState('');
  const [listSociety, setListSociety] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const list = await getListSociety();
      setListSociety(list);
      setValue(list.length > 0 ? list[0] : 'LISTE VIDE');
      if (list.length > 0) setNameCompany(list[0]);
    }
    fetchData();
  }, [setNameCompany]);

  return (
    <Autocomplete
      fullWidth
      value={value}
      disableClearable={true}
      options={listSociety}
      noOptionsText="Saisissez une société cliente"
      renderInput={(params) => <TextField {...params} label="Société cliente" />}
      onChange={(event, newValue) => {
        setValue(newValue);
        if (newValue !== null) setNameCompany(newValue);
      }}
    />
  );
}
