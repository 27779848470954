import React, { useEffect, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

import { getPropByString } from '../../../../../utils';

const autocompleteService = { current: null };

export default function InutGoogleSearch({ name, label, formik, value: valueFormik, handleCallbackAdress }) {
  const [value, setValue] = useState(valueFormik);
  const [inputValue, setInputValue] = useState(valueFormik);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setValue(valueFormik);
    setInputValue(valueFormik);
    setOptions([]);
  }, [valueFormik, handleCallbackAdress]);

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    var request = {
      input: inputValue,
      componentRestrictions: {
        country: 'fr',
      },
    };

    fetch(request, (results) => {
      if (active) {
        let newOptions = [];

        if (value !== null) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const error = getPropByString(formik.errors, name);

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.structured_formatting.main_text)}
      value={value}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      noOptionsText="Saisissez une adresse"
      onChange={(event, newValue) => {
        if (newValue === null) {
          setValue(null);
          setOptions([]);
          handleCallbackAdress(null);
        } else {
          setValue(newValue);
          setOptions(newValue ? [newValue, ...options] : options);
          const geocoder = new window.google.maps.Geocoder();
          geocoder
            .geocode({
              placeId: newValue.place_id,
            })
            .then(({ results }) => {
              handleCallbackAdress(getComponentsAdress(results));
            });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          required
          id={name}
          {...params}
          label={label}
          fullWidth
          size="small"
          error={Boolean(error)}
          helperText={error}
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        const sugg = parts.map((part, index) => {
          return (
            <Box
              key={index}
              component="span"
              sx={{
                fontWeight: part.highlight ? 'bold' : 'regular',
              }}
            >
              {part.text}
            </Box>
          );
        });
        props.key = props.id;
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid
                item
                sx={{
                  wordWrap: 'break-word',
                }}
              >
                {sugg}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );

  function getComponentsAdress(results) {
    const address_components = {
      street_number: '',
      route: '',
      postal_code: '',
      postal_code_suffix: '',
      locality: '',
      administrative_area_level_1: '',
      country: '',
      geopoint: {},
    };

    const place = results[0];

    for (const component of place.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address_components.street_number = component.long_name;
          break;
        }
        case 'route': {
          address_components.route = component.short_name;
          break;
        }
        case 'postal_code': {
          address_components.zipCode = component.long_name;
          break;
        }
        case 'postal_code_suffix': {
          address_components.postal_code_suffix = component.long_name;
          break;
        }
        case 'locality':
          address_components.locality = component.long_name;
          break;
        case 'administrative_area_level_1': {
          address_components.administrative_area_level_1 = component.short_name;
          break;
        }
        case 'country':
          address_components.country = component.long_name;
          break;
        default:
          break;
      }
    }

    address_components.street = address_components.street_number + ' ' + address_components.route;

    address_components.geopoint = {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };
    return address_components;
  }
}
