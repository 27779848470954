import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export default function DialogDeleteAssignEdlCustomer({ handleDialog, datasEdl, pricePenalty }) {
  const [hasPenalty, setHasPenalty] = useState(false);

  useEffect(() => {
    const dateBookingEdl = dayjs(datasEdl.manage.dateEDL);
    const dateDeleteEdl = dayjs(Date.now());
    const differenceInSeconds = dateBookingEdl.diff(dateDeleteEdl, 'seconds');
    const secondsIn24Hours = 24 * 60 * 60; // 24 heures en secondes
    if (differenceInSeconds < secondsIn24Hours) {
      setHasPenalty(true);
    }
  }, [datasEdl.manage.dateEDL]);

  const handleClose = (btn) => {
    if (['cancelDeleteEdlAssignCustomer', 'confirmDeleteEdlAssignCustomer'].includes(btn)) {
      handleDialog({ btn });
      return;
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Grid container spacing={1} textAlign={'center'}>
          <Grid xs={12}>
            {!hasPenalty && (
              <Typography variant="body" color="error">
                Etes-vous sûr de supprimer un EDL déjà assigné ?
              </Typography>
            )}
            {hasPenalty && (
              <Typography variant="body1" color="error">
                - Suppression 24H avant la prise de RDV -
                <br />
                une pénalité de {pricePenalty} euros sera retenue
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose('cancelDeleteEdlAssignCustomer')}>Annuler</Button>
        <Button onClick={() => handleClose('confirmDeleteEdlAssignCustomer')}>Confirmer</Button>
      </DialogActions>
    </Dialog>
  );
}
