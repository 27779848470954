const drawerWidth = 240;
const INITIAL_DISPLAY_STATE = {
  myedls: 'flex',
  newedl: 'none',
  profil: 'none',
  invoice: 'none',
  test: 'none',
};
const RESET_DISPLAY = {
  myedls: 'none',
  newedl: 'none',
  profil: 'none',
  invoice: 'none',
  test: 'none',
};
const APP_VIEWS = {
  myedls: 'Mes EDLs',
  newedl: 'Créer un EDL',
  profil: 'Profil',
  test: 'Test',
  invoice: 'Facturation',
  admin: 'Administration',
};
export { drawerWidth, INITIAL_DISPLAY_STATE, RESET_DISPLAY, APP_VIEWS };
