import * as React from 'react';
import { Typography, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { capitalizeFirstLetter, formaterNombre, getDateHourFrFromTimestamp } from '../../../utils';
import Divider from '@mui/material/Divider';
import { getGeo } from '../../../utils';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { useTheme } from '@mui/material/styles';
import HtmlTooltip from './components/HtmlTooltip';

export default function PricingEDLSelectProvider({ datasEdl, typeCompany, position, motifsRefused }) {
  const theme = useTheme();
  const companyGeo = getGeo(datasEdl[typeCompany].company.address.geopoint);
  const edlGeo = getGeo(datasEdl.possession.address.geopoint);

  function getListItems(nameAnnexes, label) {
    if (nameAnnexes === undefined) return;
    return nameAnnexes.map((item, idx) => (
      <div style={{ marginLeft: '-110px' }} key={idx}>
        <Typography variant="body" sx={{ fontSize: '12px' }}>
          {item.num === null ? label + (idx + 1) + ' : non indiqué' : label + (idx + 1) + ' : ' + item.num}
        </Typography>
      </div>
    ));
  }

  const listItemsCaves = getListItems(datasEdl.possession.annexes?.caves, 'Cave n° ');
  const listItemsParkings = getListItems(datasEdl.possession.annexes?.parkings, 'Parking n° ');

  if (datasEdl[typeCompany].company.type === 'admin') {
    typeCompany = 'customer';
  }

  const fullNameProvider = datasEdl[typeCompany].user.name + ' ' + datasEdl[typeCompany].user.surname;

  return (
    <Grid container spacing={0}>
      {motifsRefused.length > 0 &&
        motifsRefused.map(function (el, idx) {
          let suffixe = 'ème';
          if (idx === 0) {
            suffixe = 'er';
          }
          return (
            <Grid container width="100%" key={idx}>
              <Grid xs="auto" className="grid-title-card-provider">
                <Typography variant="body" className="title-card-provider-refuse">
                  {`${idx + 1}${suffixe}`} Refus
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" textAlign={'end'} className="title-card-provider-refuse">
                  <HtmlTooltip
                    placement="left-start"
                    arrow
                    title={
                      <div
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {getDateHourFrFromTimestamp(el.createdAt.seconds)}
                        <Divider color="white" />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: el.motif.replace(/\n/g, '<br />'),
                          }}
                        />
                      </div>
                    }
                  >
                    <span>Motif</span>
                  </HtmlTooltip>
                </Typography>
              </Grid>
            </Grid>
          );
        })}

      <Grid container width="100%">
        <Grid xs="auto">
          <Typography variant="body" className="title-card-provider">
            Choix de l'algorithme
          </Typography>
        </Grid>
        <Grid xs>
          <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
            {position}
          </Typography>
        </Grid>
      </Grid>
      <Grid xs sx={{ m: 1 }}>
        <Divider />
      </Grid>
      <Grid container width="100%">
        <Grid>
          <Typography variant="h6" className="main-title-card-provider">
            Tarification{' '}
            {fullNameProvider === datasEdl[typeCompany].company.name
              ? fullNameProvider
              : datasEdl[typeCompany].company.name}
          </Typography>
        </Grid>
      </Grid>
      {(fullNameProvider !== datasEdl[typeCompany].company.name && (
        <Grid container width="100%">
          <Grid xs="auto">
            <Typography variant="body" className="title-card-provider">
              Expert EDL
            </Typography>
          </Grid>
          <Grid xs>
            <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
              {fullNameProvider}
            </Typography>
          </Grid>
        </Grid>
      )) || (
        <Grid container width="100%">
          <Grid xs="auto">
            <Typography variant="body" className="title-card-provider">
               
            </Typography>
          </Grid>
          <Grid xs></Grid>
        </Grid>
      )}
      <Grid xs sx={{ m: 1 }}>
        <Divider />
      </Grid>
      <Grid container width="100%">
        <Grid xs="auto" className="grid-title-card-provider">
          <Typography variant="body" className="title-card-provider">
            Logement
          </Typography>
        </Grid>
        <Grid xs>
          <Typography variant="body1" textAlign={'end'}>
            {capitalizeFirstLetter(datasEdl.possession.typeHouse)} {datasEdl.possession.nbRoom}
          </Typography>
        </Grid>
        <Grid xs>
          <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
            {formaterNombre(datasEdl[typeCompany].pricing.tarif.base)} €
          </Typography>
        </Grid>
      </Grid>
      <Grid container width="100%">
        <Grid xs="auto" className="grid-title-card-provider" sx={{ display: 'contents' }}>
          <Link
            href={`https://www.google.com/maps/dir/?api=1&origin=${companyGeo}&destination=${edlGeo}&travelmode=driving&dirflg=d`}
            target="_blank"
            rel="noopener"
            underline="hover"
            className="title-card-provider"
          >
            <Typography component="span" sx={{ fontFamily: 'Poppins' }}>
              Voir l'itinéraire
              <DirectionsCarIcon
                sx={{
                  verticalAlign: 'top',
                  position: 'relative',
                  left: '5px',
                }}
              />
            </Typography>
          </Link>
        </Grid>
        <Grid xs>
          <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
            {datasEdl[typeCompany].pricing.deplacement.km.toFixed(2).replace('.', ',')} km
          </Typography>
        </Grid>
        <Grid xs>
          {(datasEdl[typeCompany].pricing.tarif.deplacement > 0 && (
            <Typography
              variant="body1"
              textAlign={'end'}
              sx={{
                color: theme.palette.warning.main,
              }}
            >
              <HtmlTooltip
                placement="left-end"
                arrow
                onMouseEnter={(e) => (e.target.style.cursor = 'pointer')}
                title={
                  <React.Fragment>
                    <Typography width="250px">Frais de déplacement</Typography>
                    <Grid container justifyContent="space-between">
                      <Grid>Mode :</Grid>
                      <Grid>{datasEdl[typeCompany].pricing.deplacement.mode}</Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Grid>Kilométrage maximun :</Grid>
                      <Grid>{datasEdl[typeCompany].pricing.deplacement.maxBorne.toFixed(2).replace('.', ',')} km</Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Grid> Kilométrage relevé :</Grid>
                      <Grid>{datasEdl[typeCompany].pricing.deplacement.km.toFixed(2).replace('.', ',')} km</Grid>
                    </Grid>
                    <Divider
                      sx={{
                        backgroundColor: 'white',
                        m: 1,
                      }}
                    />
                    <Grid container justifyContent="space-between">
                      <Grid>Majoration :</Grid>
                      <Grid>{datasEdl[typeCompany].pricing.deplacement.majoTarif} €</Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                <span>{formaterNombre(datasEdl[typeCompany].pricing.tarif.deplacement)} €</span>
              </HtmlTooltip>
            </Typography>
          )) || (
            <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
              {formaterNombre(datasEdl[typeCompany].pricing.tarif.deplacement)} €
            </Typography>
          )}
        </Grid>
      </Grid>

      {datasEdl.possession.typeHouse !== 'parking' && (
        <>
          <Grid container width="100%">
            <Grid xs="auto" className="grid-title-card-provider">
              <Typography variant="body" className="title-card-provider">
                Bien
              </Typography>
            </Grid>
            <Grid xs>
              <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                {capitalizeFirstLetter(datasEdl.possession.typeRent)}
              </Typography>
            </Grid>
            <Grid xs>
              <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                {formaterNombre(datasEdl[typeCompany].pricing.tarif.meuble)} €
              </Typography>
            </Grid>
          </Grid>
          <Grid container width="100%">
            <Grid xs="auto" className="grid-title-card-provider">
              <Typography variant="body" className="title-card-provider">
                Superficie
              </Typography>
            </Grid>
            <Grid xs>
              <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                {datasEdl.possession.area} m²
              </Typography>
            </Grid>
            <Grid xs>
              {(datasEdl[typeCompany].pricing.tarif.superficie > 0 && (
                <Typography
                  variant="body1"
                  textAlign={'end'}
                  sx={{
                    color: theme.palette.warning.main,
                  }}
                >
                  <HtmlTooltip
                    placement="left-end"
                    arrow
                    onMouseEnter={(e) => (e.target.style.cursor = 'pointer')}
                    title={
                      <React.Fragment>
                        <Typography width="250px">Frais supplémentaires</Typography>

                        <Grid container justifyContent="space-between">
                          <Grid>Nb de pièces :</Grid>
                          <Grid>{datasEdl[typeCompany].pricing.nbRoom}</Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                          <Grid>Superficie maximun :</Grid>
                          <Grid>{datasEdl[typeCompany].pricing.maxSuperficie} m²</Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                          <Grid>Superficie renseignée :</Grid>
                          <Grid>{datasEdl[typeCompany].pricing.superficie} m²</Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                          <Grid>Superficie excédentaire :</Grid>
                          <Grid> {datasEdl[typeCompany].pricing.supSuperficie} m²</Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                          <Grid>Prix par m² excédentaire :</Grid>
                          <Grid>{formaterNombre(datasEdl[typeCompany].pricing.priceBySupMeters)} €</Grid>
                        </Grid>
                        <Divider
                          sx={{
                            backgroundColor: 'white',
                            m: 1,
                          }}
                        />
                        <Grid container justifyContent="space-between">
                          <Grid>Majoration :</Grid>
                          <Grid>{formaterNombre(datasEdl[typeCompany].pricing.tarif.superficie)} €</Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  >
                    <span>{formaterNombre(datasEdl[typeCompany].pricing.tarif.superficie)} €</span>
                  </HtmlTooltip>
                </Typography>
              )) || (
                <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                  {formaterNombre(datasEdl[typeCompany].pricing.tarif.superficie)} €
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container width="100%">
            <Grid xs="auto" className="grid-title-card-provider">
              <Typography variant="body" className="title-card-provider">
                Vidéo
              </Typography>
            </Grid>
            <Grid xs>
              <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                {datasEdl.manage.video}
              </Typography>
            </Grid>
            <Grid xs>
              <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                {formaterNombre(datasEdl[typeCompany].pricing.tarif.video)} €
              </Typography>
            </Grid>
          </Grid>
          <Grid xs sx={{ m: 1 }}>
            <Divider />
          </Grid>
          <Grid container width="100%">
            <Typography variant="span" sx={{ mb: 1 }} className="title-card-provider">
              Annexes
            </Typography>
          </Grid>
          {datasEdl.possession.annexes.nbCaves > 0 && (
            <Grid container xs={12} width="100%">
              <Grid xs="auto" className="grid-title-card-provider">
                <Typography variant="body" className="title-card-provider">
                  Caves
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" textAlign={'end'}>
                  {datasEdl.possession.annexes.nbCaves}
                </Typography>
                {listItemsCaves}
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                  {formaterNombre(datasEdl[typeCompany].pricing.tarif.caves)} €
                </Typography>
              </Grid>
            </Grid>
          )}
          {datasEdl.possession.annexes.nbParkings > 0 && (
            <Grid container xs={12}>
              <Grid xs="auto" className="grid-title-card-provider">
                <Typography variant="body" className="title-card-provider">
                  Parkings
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" textAlign={'end'}>
                  {datasEdl.possession.annexes.nbParkings}
                </Typography>
                {listItemsParkings}
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                  {formaterNombre(datasEdl[typeCompany].pricing.tarif.parkings)} €
                </Typography>
              </Grid>
            </Grid>
          )}
          {datasEdl.possession.annexes.nbBalcony > 0 && (
            <Grid container xs={12}>
              <Grid xs="auto" className="grid-title-card-provider">
                <Typography variant="body" className="title-card-provider">
                  Balcons
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" textAlign={'end'}>
                  {datasEdl.possession.annexes.nbBalcony}
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                  {formaterNombre(datasEdl[typeCompany].pricing.tarif.balcony)} €
                </Typography>
              </Grid>
            </Grid>
          )}
          {datasEdl.possession.annexes.nbGarden > 0 && (
            <Grid container xs={12}>
              <Grid xs="auto" className="grid-title-card-provider">
                <Typography variant="body" className="title-card-provider">
                  Jardins
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" textAlign={'end'}>
                  {datasEdl.possession.annexes.nbGarden}
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                  {formaterNombre(datasEdl[typeCompany].pricing.tarif.garden)} €
                </Typography>
              </Grid>
            </Grid>
          )}
          {datasEdl.possession.annexes.nbOther > 0 && (
            <Grid container xs={12}>
              <Grid xs="auto" className="grid-title-card-provider">
                <Typography variant="body" className="title-card-provider">
                  Autres
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" textAlign={'end'}>
                  {datasEdl.possession.annexes.nbOther}
                </Typography>
              </Grid>
              <Grid xs>
                <Typography variant="body1" className="edlInfos-item" textAlign={'end'}>
                  {formaterNombre(datasEdl[typeCompany].pricing.tarif.other)} €
                </Typography>
              </Grid>
            </Grid>
          )}

          {datasEdl.possession.annexes.nbCaves === 0 &&
            datasEdl.possession.annexes.nbParkings === 0 &&
            datasEdl.possession.annexes.nbBalcony === 0 &&
            datasEdl.possession.annexes.nbGarden === 0 &&
            datasEdl.possession.annexes.nbOther === 0 && (
              <Grid container xs={12}>
                <Grid xs="auto">
                  <Typography variant="body1">Aucune annexe</Typography>
                </Grid>
                <Grid xs>
                  <Typography variant="body1" textAlign={'end'}></Typography>
                </Grid>
              </Grid>
            )}

          <Grid xs sx={{ m: 1 }}>
            <Divider />
          </Grid>

          <Grid container width="100%">
            <Grid xs="auto">
              <Typography variant="body" className="title-card-provider">
                Montant facturé par l'agent EDL
              </Typography>
            </Grid>
            <Grid xs>
              <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
                {formaterNombre(datasEdl['provider'].pricing.tarif.total)} €
              </Typography>
            </Grid>
          </Grid>
          <Grid container width="100%">
            <Grid xs="auto">
              <Typography variant="body" className="title-card-provider">
                Montant facturé par SKY RENOV
              </Typography>
            </Grid>
            <Grid xs>
              <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
                {formaterNombre(datasEdl['customer'].pricing.tarif.total)} €
              </Typography>
            </Grid>
          </Grid>
          <Grid xs sx={{ m: 1 }}>
            <Divider />
          </Grid>
          <Grid container width="100%">
            <Grid xs="auto">
              <Typography variant="body" className="title-benef-card-provider">
                Bénéfice dégagée
              </Typography>
            </Grid>
            <Grid xs>
              <Typography variant="body1" className="item-benef-card-provider" textAlign="end" fontWeight="bold">
                {formaterNombre(datasEdl['customer'].pricing.tarif.total - datasEdl['provider'].pricing.tarif.total)} €
              </Typography>
            </Grid>
          </Grid>
          {/* 
          <Grid xs sx={{ m: 1 }}>
            <Divider />
          </Grid>
          <Grid container width="100%">
            <Grid>
              <Typography variant="h6" className="main-title-card-provider">
                Indicateur
              </Typography>
            </Grid>
          </Grid>
          <Grid container width="100%">
            <Grid xs="auto">
              <Typography variant="body" className="title-card-provider">
                CA du mois en cours (Octobre)
              </Typography>
            </Grid>
            <Grid xs>
              <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
                {formaterNombre(datasEdl['customer'].pricing.tarif.total)} €
              </Typography>
            </Grid>
          </Grid>
          <Grid container width="100%">
            <Grid xs="auto">
              <Typography variant="body" className="title-card-provider">
                CA depuis le 1er Janvier 2023
              </Typography>
            </Grid>
            <Grid xs>
              <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
                {formaterNombre(datasEdl['customer'].pricing.tarif.total)} €
              </Typography>
            </Grid>
          </Grid>
          <Grid container width="100%">
            <Grid xs="auto">
              <Typography variant="body" className="title-card-provider">
                % EDL Accepté
              </Typography>
            </Grid>
            <Grid xs>
              <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
                93%
              </Typography>
            </Grid>
          </Grid>
          <Grid container width="100%">
            <Grid xs="auto">
              <Typography variant="body" className="title-card-provider">
                % EDL Annulé sur les EDL acceptés
              </Typography>
            </Grid>
            <Grid xs>
              <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
                6%
              </Typography>
            </Grid>
          </Grid>
          <Grid container width="100%">
            <Grid xs="auto">
              <Typography variant="body" className="title-card-provider">
                Indice de confiance
              </Typography>
            </Grid>
            <Grid xs>
              <Typography variant="body1" className="edlInfos-item" textAlign="end" fontWeight="bold">
                <Rating name="half-rating-read" defaultValue={4} precision={0.5} readOnly />
              </Typography>
            </Grid>
          </Grid>
          */}
        </>
      )}
    </Grid>
  );
}
