import React, { useRef, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EventIcon from '@mui/icons-material/Event';
import SearchIcon from '@mui/icons-material/Search';
import SelectSortEDL from './SelectSortEDL';
import { InputAdornment, TextField } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { AppContext } from '../../contexts-api/AppContextProvider';

import ButtonGroupStatus from './ButtonGroupStatus';
import { Typography } from '@mui/material';
import { capitalizeFirstLetter } from '../../utils';

function ToolBar({
  typeView,
  viewTitle,
  setViewTitle,
  handleTypeView: handleTypeViewParent,
  handleStatus: handleStatusParent,
  calendarRef,
  handleSearchText,
  handleSort,
}) {
  const { profil } = useContext(AppContext);

  const [view, setView] = useState(typeView);
  const [variantBtnMonth, setVariantBtnMonth] = useState('contained');

  const handleTypeView = () => {
    view === 'calendar' ? setView('items') : setView('calendar');
    handleTypeViewParent(view === 'calendar' ? 'items' : 'calendar');
  };

  const handleStatus = (status) => {
    handleStatusParent(status);
  };

  const handleMonth = () => {
    const calendar = calendarRef.current.getApi();
    calendar.changeView('dayGridMonth');
    setVariantBtnMonth('contained');
    setViewTitle(calendar.view.title);
  };

  const handleWeek = () => {
    const calendar = calendarRef.current.getApi();
    calendar.changeView('timeGridWeek');
    setVariantBtnMonth('outlined');
    setViewTitle(calendar.view.title);
  };

  const handleNext = () => {
    const calendar = calendarRef.current.getApi();
    calendar.next();
    setViewTitle(calendar.view.title);
  };

  const handlePrevious = () => {
    const calendar = calendarRef.current.getApi();
    calendar.prev();
    setViewTitle(calendar.view.title);
  };

  const handleSortChange = (value) => {
    handleSort(value.split('-'));
  };

  const delayedFunction = (value) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      handleSearchText(value);
    }, 300);
  };

  const handleSearchTextChange = (event) => {
    event.preventDefault();
    const value = textFieldRef.current.value;
    delayedFunction(value);
  };

  const textFieldRef = useRef(null);
  const timeoutId = useRef(null);

  return (
    <Grid container spacing={1}>
      <Grid xs="auto">
        {view === 'calendar' ? (
          <Button onClick={handleTypeView} variant="contained" endIcon={<ManageSearchIcon />}>
            Rechercher
          </Button>
        ) : (
          <Button onClick={handleTypeView} variant="contained" endIcon={<EventIcon />}>
              Agenda  
          </Button>
        )}
      </Grid>

      <Grid xs={12} md="auto">
        <ButtonGroupStatus handleStatus={handleStatus} profil={profil} />
      </Grid>
      {view === 'items' && (
        <Grid container direction="row">
          <Grid md="auto">
            <SelectSortEDL onChangeParent={handleSortChange} />
          </Grid>
          <Grid md>
            <TextField
              size="small"
              fullWidth
              label="Rechercher"
              onChange={handleSearchTextChange}
              inputRef={textFieldRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      )}
      {view === 'calendar' && (
        <>
          <Grid
            xs={12}
            md
            textAlign={{
              xs: 'left',
              md: 'center',
            }}
          >
            <Typography
              variant="body"
              sx={{
                fontSize: '22px',
                color: '#1791D1',
                fontWeight: '800',
              }}
            >
              {capitalizeFirstLetter(viewTitle)}
            </Typography>
          </Grid>

          <Grid md={12} lg="auto">
            <ButtonGroup>
              <Button variant={variantBtnMonth} onClick={handleMonth}>
                Mois
              </Button>
              <Button variant={variantBtnMonth === 'contained' ? 'outlined' : 'contained'} onClick={handleWeek}>
                Semaine
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid>
            <ButtonGroup variant="contained">
              <Button variant="contained" onClick={handlePrevious} endIcon={<ArrowBackIosIcon />}></Button>
              <Button variant="contained" onClick={handleNext} endIcon={<ArrowForwardIosIcon />}></Button>
            </ButtonGroup>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default ToolBar;
