import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.warning.main,
      color: 'white',
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }),
);

export default HtmlTooltip;
