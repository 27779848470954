import React, { useContext } from 'react';
import { AppContext } from '../../contexts-api/AppContextProvider';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import PricingGrid from './PricingGrid';
import CardCompany from './CardCompany';
import CardUser from './CardUser';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';

function Profile() {
  const { profil } = useContext(AppContext);
  const priceList = profil.priceList;
  return (
    <Box sx={{ padding: 1 }}>
      <Grid container spacing={{ xs: 1, md: 2 }} disableEqualOverflow>
        <Grid xs={12}>
          <Typography variant="h6" color="primary">
            Informations générales
          </Typography>
          <Divider />
        </Grid>
        <Grid container alignItems="stretch">
          <Grid xs={12} md="auto">
            <CardUser user={profil.user} />
          </Grid>
          <Grid xs={12} md="auto">
            <CardCompany company={profil.company} />
          </Grid>
        </Grid>

        {profil.company.type !== 'admin' && (
          <>
            <Grid xs={12}>
              <Typography variant="h6" color="primary">
                Grille tarifaire
              </Typography>
              <Divider />
            </Grid>
            <Grid xs={12} lg={6}>
              <PricingGrid typeHouse="appartement" priceList={priceList} />
            </Grid>
            <Grid xs={12} lg={6}>
              <PricingGrid typeHouse="maison" priceList={priceList} />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default Profile;
