import dayjs from 'dayjs';
import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { getPropByString } from '../../../../../utils';

export default function DateSky({ name, formik, value: _value, formIsSubmit }) {
  const handleChange = (e) => {
    if (e === null) return;
    formik.setFieldValue(name, dayjs.tz(e).toDate(), formIsSubmit);
  };

  const error = getPropByString(formik.errors, name);
  console.log('je suis ici');

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      <DesktopDatePicker
        onChange={handleChange}
        minDate={dayjs().add(2, 'day')}
        maxDate={dayjs().add(6, 'month')}
        value={dayjs(_value)}
        slotProps={{
          textField: {
            id: name,
            name: name,
            size: 'small',
            required: true,
            error: Boolean(error),
            helperText: error,
          },
        }}
      />
    </LocalizationProvider>
  );
}
