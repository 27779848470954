import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { PaperMyEdls } from '../../Theme';
import getListProviders from './getListProviders';
import { getStylesPapers } from './../../Theme';
import PricingEDLSelectProvider from '../components/ViewEDL/PricingEDLSelectProvider';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { clone } from 'lodash';

export default function InputListProvider({ datasEdl, setProvider, setIsLoading }) {
  const [listProviders, setListProviders] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const list = await getListProviders(datasEdl);
      setListProviders(list);
      setIsLoading(false);
      setLoader(false);
      setClicked({
        user: { uid: datasEdl?.provider?.user?.uid ?? list[0]?.user?.uid },
      });
      setProvider(datasEdl?.provider ?? list[0]);
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleOnClick = (provider) => {
    setClicked(provider);
    setProvider(provider);
  };

  const getMotifRefuses = (uidProvider) => {
    return datasEdl?.admin
      ? datasEdl.admin.refusByProviders.list.filter(function (el) {
          return el.userProvider.uid === uidProvider;
        })
      : {};
  };

  return (
    <>
      {(loader && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )) || (
        <Grid container spacing={2} xs={12}>
          {listProviders.map((infosProvider, idx) => {
            const motifsRefused = getMotifRefuses(infosProvider.user.uid);
            const color = getStylesPapers('assign');
            const newDatasEdl = clone(datasEdl);
            newDatasEdl['provider'] = infosProvider;

            return (
              <Grid key={idx} xs={12} lg={4} flexDirection="row" flexWrap="nowrap">
                <PaperMyEdls
                  color={color}
                  onClick={() => handleOnClick(infosProvider)}
                  clicked={clicked?.user?.uid === infosProvider.user.uid ? 'true' : 'false'}
                >
                  <PricingEDLSelectProvider
                    datasEdl={newDatasEdl}
                    typeCompany="provider"
                    position={idx + 1}
                    motifsRefused={motifsRefused}
                  />
                </PaperMyEdls>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
}
