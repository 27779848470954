import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertSky(props) {
  const [open, setOpen] = React.useState(false);
  const [countAlert, setCountAlert] = React.useState(0);

  if (props.countAlert !== countAlert) {
    setOpen(true);
    setCountAlert(props.countAlert);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      TransitionComponent={TransitionRight}
    >
      <Alert onClose={handleClose} severity={props.severity} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}
