import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CancelIcon from '@mui/icons-material/Cancel';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import dayjs from 'dayjs';
import { handleDownload } from './ViewEDL/utils';
import { formatPhoneNumber, capitalizeFirstLetter } from '../../utils';

export default function DialogSky({ open, handleDialog, status, children, isLoading, datasEdl }) {
  const handleClose = (btn) => {
    handleDialog({ btn });
  };

  /*
  const handleClickICS = () => {
    let title = datasEdl.manage.typeEDL === 'entrant' ? 'EDLE' : 'EDLS';
    title += ' ' + datasEdl.tenants[0].surname;
    const dateEDL = dayjs.tz(datasEdl.manage.dateEDL);
    const event = {
      title: title, // Supposons que 'title' est déjà défini quelque part
      start: [
        dateEDL.year(),
        dateEDL.month() + 1,
        dateEDL.date(), // Utilisez 'date()' pour obtenir le jour du mois
        dateEDL.hour(),
        dateEDL.minute(),
      ],
      end: [
        dateEDL.year(),
        dateEDL.month() + 1,
        dateEDL.date(), // Utilisez 'date()' pour obtenir le jour du mois
        dateEDL.add(1, 'hour').hour(),
        dateEDL.minute(),
      ],
      htmlContent: '<!DOCTYPE html><html>' + getHtml() + '</html>',
      location: datasEdl.possession.address.street + ', ' + datasEdl.possession.address.locality,
      geo: {
        lat: datasEdl.possession.address.geopoint._lat,
        lon: datasEdl.possession.address.geopoint._long,
      },
      status: 'CONFIRMED',
      alarms: [
        {
          action: 'display',
          description: title,
          trigger: {
            hours: 1,
            minutes: 30,
            before: true,
          },
        },
      ],
    };
    handleDownload(title + '.ics', event);
  };

  const getHtml = () => {
    const headerHtml = `
    <!DOCTYPE html>
    <html lang="fr">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&display=swap"
          rel="stylesheet"
        />
        <title>Mail EDL SKYRENOV</title>
        <style>
          body {
            margin: 0;
            padding: 0;
            min-height: 100%;
            min-width: 100%;
            font-family: "Roboto", sans-serif;
            background-color: rgb(171, 214, 250);
            position : absolute;
            justify-content: center;
            display: flex;
          }
          .main-container {
            display: flex;
            background-color: #ffffff;
            width: 600px;
            flex-direction: column;
            padding : 18px;
          }
        </style>
      </head>
      <body>
        <div class="main-container">
        `;

    const footerHtml = `
          <div style="display: flex; justify-content: center; align-items: center">
            <img
                src="https://d375w6nzl58bw0.cloudfront.net/uploads/6b56463cb4779f9a5d40cc413bfd0edd4085b4b120add001192fc104c2a5578e.png"
                alt="logo Skyrenov"
                style="width: 100px; height: auto"
              />
          </div>
        </div>
      </body>
    </html>
    `;

    const edl = datasEdl;
    console.log(edl.manage.dateEDL);
    const heureFrancaise = dayjs.tz(edl.manage.dateEDL);
    const datePrevue = heureFrancaise.format('DD/MM/YYYY');
    const heurePrevue = heureFrancaise.format('HH:mm');

    const htmlTenants = () => {
      let tmpHtml = '';
      edl.tenants.forEach((tenant, idx) => {
        tmpHtml =
          tmpHtml +
          `
           <tr>
             <td colspan="2">
              <hr />
             </td>
           </tr>
           <tr>
            <td style="text-align: left;">
              <span class="typo-edl-col1">Locataire ${edl.tenants.length > 1 ? 'n° ' + (idx + 1) : ''}</span>
            </td>
            <td style="text-align: right">
              <span class="typo-edl-col2">${tenant.civility} ${tenant.surname} ${tenant.name ?? ''}</span>
            </td>
          </tr>
          <tr>
            <td style="text-align: left;">
              <span class="typo-edl-col1">T&eacute;l&eacute;phone</span>
            </td>
            <td style="text-align: right">
              <span class="typo-edl-col2">${formatPhoneNumber(tenant.phone)}</span>
            </td>
          </tr>
          <tr>
            <td style="text-align: left;">
              <span class="typo-edl-col1">Email</span>
            </td>
            <td style="text-align: right">
              <span class="typo-edl-col2">${tenant.email ?? 'Non renseign&eacute;e'}</span>
            </td>
          </tr>
          `;
      });
      return tmpHtml;
    };

    const infosHtml = `
      <div class="paper">
        <div>Informations</div>
        <table cellspacing="0" style="width: 100%">
          <tr>
            <td style="text-align: left;">
              <span class="typo-edl-col1">Date pr&eacute;vue</span>
            </td>
            <td style="text-align: right;">
              <span class="typo-edl-col2">${datePrevue}</span>
            </td>
          </tr>
          <tr>
          <td style="text-align: left;">
            <span class="typo-edl-col1">Heure pr&eacute;vue</span>
          </td>
          <td style="text-align: right;">
            <span class="typo-edl-col2">${heurePrevue}</span>
          </td>
        </tr>
          <tr>
            <td style="text-align: left;">
              <span class="typo-edl-col1">EDL</span>
            </td>
            <td style="text-align: right;">
              <span class="typo-edl-col2">${capitalizeFirstLetter(edl.manage.typeEDL)}</span>
            </td>
          </tr>
          <tr>
            <td style="text-align: left; vertical-align: top;">
              <span class="typo-edl-col1">Adresse</span>
            </td>
            <td style="text-align: right;">
              <span class="typo-edl-col2"
                >${edl.possession.address.street}<br/>${
                  edl.possession.address.complement !== null ? edl.possession.address.complement + '<br/>' : ''
                }${edl.possession.address.zipCode} ${edl.possession.address.locality}</span
              >
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <hr />
            </td>
          </tr>
          <tr>
            <td style="text-align: left;">
              <span class="typo-edl-col1">Propri&eacute;taire</span>
            </td>
            <td style="text-align: right;">
              <span class="typo-edl-col2">${edl.possession.nameOwner}</span>
            </td>
          </tr>
          ${htmlTenants()}
          <tr>
            <td colspan="2">
              <hr />
            </td>
          </tr>
          <tr>
            <td style="text-align: left;">
             <span class="typo-edl-title-grades">Notes</span>
           </td>
          </tr>
          <tr>
            <td style="text-align: left;padding-top: 8px;">
              <span class="typo-edl-col2">${
                edl.grades !== null ? edl.grades.replace('\n', '<br/>') : 'Non renseign&eacute;e'
              }</span>
            </td>
          </tr>
        </table>
        </div>
    `;

    const templateHtml = headerHtml + infosHtml + footerHtml;

    return templateHtml;
  };

  const buttonCalendar = () => {
    return (
      <Button>
        <img
          src="icon-calendrier.png"
          alt="calendrier"
          style={{
            width: '48px',
            height: '48px',
            cursor: 'pointer',
          }} // Vous pouvez ajuster la taille selon vos besoins
          onClick={handleClickICS}
        />
      </Button>
    );
  };*/

  const buttons = () => {
    const btn = (action) => {
      const defaultDynamicAttributes = {
        onClick: () => handleClose(action),
        variant: 'contained',
        color: 'primary',
        startIcon: <ThumbUpIcon />,
        disabled: isLoading ?? false,
      };

      const actionMappings = {
        cancelCreateEdl: {
          variant: 'outlined',
          color: 'error',
          startIcon: <CancelIcon />,
          label: 'Annuler',
          disabled: false,
        },
        cancelSubmitEdl: {
          variant: 'outlined',
          color: 'error',
          startIcon: <CancelIcon />,
          label: 'Annuler',
          disabled: false,
        },
        cancelModifiedEdl: {
          variant: 'outlined',
          color: 'error',
          startIcon: <CancelIcon />,
          label: 'Annuler',
          disabled: false,
        },
        canceAssignProvider: {
          variant: 'outlined',
          color: 'error',
          startIcon: <CancelIcon />,
          label: 'Annuler',
          disabled: false,
        },
        canceListProviders: {
          variant: 'outlined',
          color: 'error',
          startIcon: <CancelIcon />,
          label: 'Annuler',
          disabled: false,
        },
        cancelAssignProvider: {
          variant: 'outlined',
          color: 'error',
          startIcon: <CancelIcon />,
          label: 'Annuler',
          disabled: false,
        },
        cancelEdlProvider: {
          variant: 'outlined',
          color: 'error',
          startIcon: <CancelIcon />,
          label: 'Annuler',
          disabled: false,
        },
        cancelBookingProvider: {
          variant: 'outlined',
          color: 'error',
          startIcon: <CancelIcon />,
          label: 'Annuler',
          disabled: false,
        },
        acceptCreateEdl: {
          endIcon: <ThumbUpIcon />,
          label: 'Accepter',
        },
        acceptModifiedEdl: {
          endIcon: <ThumbUpIcon />,
          label: 'Accepter',
        },
        assignlistProviders: {
          endIcon: <ThumbUpIcon />,
          label: 'Accepter',
        },
        modifiedSubmitEdl: {
          startIcon: <ModeEditIcon />,
          label: 'Modifier',
        },
        modifiedModifiedEdl: {
          startIcon: <ModeEditIcon />,
          label: 'Modifier',
        },
        deleteSubmitEdl: {
          color: 'error',
          startIcon: <DeleteForeverIcon />,
          label: 'Supprimer',
        },
        deleteModifiedEdl: {
          color: 'error',
          startIcon: <DeleteForeverIcon />,
          label: 'Supprimer',
        },
        refuseEdlCustomer: {
          color: 'error',
          endIcon: <ThumbDownIcon />,
          label: 'Refuser',
        },
        deleteModifiedEdlAssign: {
          color: 'error',
          startIcon: <DeleteForeverIcon />,
          label: 'Supprimer',
        },
        showListProvider: {
          startIcon: <PersonSearchIcon />,
          label: 'Affecter',
        },
        refuseEdlProvider: {
          color: 'error',
          endIcon: <ThumbDownIcon />,
          label: 'Refuser',
        },
        acceptEdlProvider: {
          endIcon: <ThumbUpIcon />,
          label: 'Accepter',
        },
        confirmBookingProvider: {
          endIcon: <ThumbUpIcon />,
          label: 'Confirmer',
        },
        modifiedBookingProvider: {
          endIcon: <AccessTimeIcon />,
          label: 'Modifier',
        },
        finishEdlProvider: {
          endIcon: <CheckCircleOutlineIcon />,
          color: 'finish',
          label: 'Finaliser',
        },
        modifyReportEdlProvider: {
          endIcon: <UploadFileIcon />,
          color: 'control',
          label: 'Remplacer le rapport EDL',
        },
        viewReportEdlProvider: {
          startIcon: <PictureAsPdfIcon />,
          color: 'assign',
          label: 'Visualiser le rapport',
        },
        refuseReportEdlProvider: {
          color: 'error',
          endIcon: <ThumbDownIcon />,
          label: 'Refuser',
        },
        acceptReportEdlProvider: {
          endIcon: <ThumbUpIcon />,
          color: 'finish',
          label: 'Valider',
        },
        deleteModifiedEdlAssignDisable: {
          color: 'error',
          startIcon: <DeleteForeverIcon />,
          label: 'Supprimer',
          disabled: true,
        },
      };

      const dynamicAttributes = actionMappings[action] || defaultDynamicAttributes;
      const { label, ...attributes } = {
        ...defaultDynamicAttributes,
        ...dynamicAttributes,
      };
      if (attributes.hasOwnProperty('startIcon') && attributes.hasOwnProperty('endIcon')) delete attributes.startIcon;

      return <Button {...attributes}>{label}</Button>;
    };

    switch (status) {
      case 'creatingEdl':
        return (
          <>
            {btn('cancelCreateEdl')}
            {btn('acceptCreateEdl')}
          </>
        );
      case 'submitEdl':
        return (
          <>
            {btn('cancelSubmitEdl')}
            {btn('modifiedSubmitEdl')}
            {btn('deleteSubmitEdl')}
          </>
        );
      case 'modifiedEdlFromNewEdl':
        return (
          <>
            {btn('cancelModifiedEdl')}
            {btn('deleteModifiedEdl')}
            {btn('acceptModifiedEdl')}
          </>
        );
      case 'modifiedEdlFromMyEdls':
        return (
          <>
            {btn('cancelModifiedEdl')}
            {btn('modifiedModifiedEdl')}
            {btn('deleteModifiedEdl')}
            {btn('acceptModifiedEdl')}
          </>
        );
      case 'assignProvider':
        return (
          <>
            {btn('cancelAssignProvider')}
            {btn('refuseEdlCustomer')}
            {btn('showListProvider')}
          </>
        );
      case 'listProviders':
        return (
          <>
            {btn('canceListProviders')}
            {btn('assignlistProviders')}
          </>
        );
      case 'acceptRefuseProvider':
        return (
          <>
            {btn('cancelEdlProvider')}
            {btn('refuseEdlProvider')}
            {btn('acceptEdlProvider')}
          </>
        );
      case 'bookingProvider':
        return (
          <>
            {btn('cancelBookingProvider')}
            {btn('confirmBookingProvider')}
          </>
        );
      case 'acceptedEdlProvider':
        return (
          <>
            {btn('cancelEdlProvider')}
            {btn('refuseEdlProvider')}
            {btn('modifiedBookingProvider')}
            {btn('finishEdlProvider')}
            {/*buttonCalendar()*/}
          </>
        );
      case 'waitReportEdlValidation':
        return (
          <>
            {btn('cancelEdlProvider')}
            {btn('modifyReportEdlProvider')}
          </>
        );
      case 'controlEdlProvider':
        return (
          <>
            {btn('cancelEdlProvider')}
            {btn('viewReportEdlProvider')}
            {btn('refuseReportEdlProvider')}
            {btn('acceptReportEdlProvider')}
          </>
        );
      case 'finishEdl':
        return (
          <>
            {btn('cancelEdlProvider')}
            {btn('viewReportEdlProvider')}
          </>
        );

      case 'consultAssignEdlCustomer':
        return (
          <>
            {btn('cancelEdlProvider')}
            {btn('deleteModifiedEdlAssign')}
            {/*buttonCalendar()*/}
          </>
        );

      case 'controlingEdlCustomer':
        return (
          <>
            {btn('cancelEdlProvider')}
            {btn('deleteModifiedEdlAssignDisable')}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            minWidth: { xs: '100%', lg: '90%' },
            minHeight: { xs: '100%', lg: '90%' },
            backgroundColor: '#f5f5f5',
          },
        }}
        maxWidth={'md'}
      >
        <DialogContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {children}
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {buttons()}
        </DialogActions>
      </Dialog>
    </>
  );
}
