import { createRoot } from 'react-dom/client';

import React, { useRef, useState, useContext, useEffect } from 'react';

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import { AppContext } from '../../contexts-api/AppContextProvider';

import { useTheme } from '@mui/material/styles';
import './calendar.css';
import ToolBar from './Toolbar';
import { capitalizeFirstLetter } from '../../utils';
import { filterAndSortEdls } from './utils';
import ConfirmlDialogSky from '../components/ConfirmDialogSky';
import dayjs from 'dayjs';
dayjs.locale('fr');

function ViewCalendar({ edls, countEdls, handleOnClick, typeView, setTypeView, handleNewEdl, typeCompany }) {
  const { profil } = useContext(AppContext);
  const theme = useTheme();
  const [events, setEvents] = useState([]);
  const [viewTitle, setViewTitle] = useState('');
  const [status, setStatus] = useState(['submit', 'assign', 'waiting', 'control', 'finish']);
  const [messageDialog, setMessageDialog] = useState(null);
  const calendarRef = useRef(null);

  const handleTypeView = (typeView) => {
    setTypeView(typeView);
  };

  const handleStatus = (status) => {
    setStatus([...status]);
  };

  const getEvents = () => {
    const filterEdls = filterAndSortEdls(edls, status);

    const tempEvents = [];
    for (const [, datas] of filterEdls) {
      if (
        profil.company.type !== 'customer' &&
        datas.metadatas.status === 'submit' &&
        datas.metadatas.isModifying === true
      )
        continue;
      const startDate = dayjs.tz(datas.manage.dateEDL).format();
      const endDate = dayjs.tz(startDate).add(1, 'hour').format();
      tempEvents.push({
        title:
          (datas.manage.typeEDL === 'entrant' ? 'EDLE' : 'EDLS') +
          '</br>' +
          datas.tenants[0].surname +
          (datas.tenants[0].name ? ' ' + datas.tenants[0].name : '') +
          '</br>' +
          datas.possession.address.locality,
        start: startDate,
        end: endDate,
        allDay: ['submit', 'waiting'].includes(datas.metadatas.status) ? true : false,
        edl: datas,
      });
    }
    setEvents(tempEvents);
  };

  const injectCss = () => {
    const styleSheet = document.styleSheets[0];
    const eventStatuses = ['submit', 'waiting', 'assign', 'control', 'finish'];

    eventStatuses.forEach((status, index) => {
      const backgroundColor = theme.palette[status].main;
      const hoverBackgroundColor = theme.palette[`${status}Dark`].main;

      styleSheet.insertRule(
        `td.fc-day a.fc-event.status-${status} {
                background-color: ${backgroundColor} !important;
                color: white;
                border : 0px;
            }`,
        index * 2,
      );

      styleSheet.insertRule(
        `td.fc-day a.fc-event.status-${status}:hover {
                background-color: ${hoverBackgroundColor} !important;
                color: white;
                border : 0px;
            }`,
        index * 2 + 1,
      );

      styleSheet.insertRule(
        `div.fc-popover a.fc-event.status-${status} {
                background-color: ${backgroundColor} !important;
                color: white;
                border : 0px;
            }`,
        index * 2 + 2,
      );

      styleSheet.insertRule(
        `div.fc-popover a.fc-event.status-${status}:hover {
                background-color: ${hoverBackgroundColor} !important;
                color: white;
                border : 0px;
            }`,
        index * 2 + 3,
      );
    });
  };

  useEffect(() => {
    getEvents();
    injectCss();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, countEdls]);

  const handleEventClick = (info) => {
    handleOnClick({ edl: info.event.extendedProps.edl });
  };

  const handleDateClick = (info) => {
    const minDate = dayjs().add(1, 'day');
    const currentDate = dayjs(info.date);
    if (['admin', 'provider'].includes(profil.company.type)) return;
    if (currentDate.isAfter(minDate)) {
      setMessageDialog([
        info,
        <>
          {capitalizeFirstLetter(currentDate.format('dddd D MMMM YYYY'))}
          <br />
          Souhaitez-vous créer un EDL à cette date ?
        </>,
      ]);
    }
  };

  const handleConfirmClose = (info) => {
    setMessageDialog(null);
    if (info?.date !== undefined) handleNewEdl(info.date);
  };

  const handleDayCellClassNames = (dayCell) => {
    const minDate = typeCompany === 'customer' ? dayjs().add(1, 'day') : dayjs().add(-1, 'day');
    const currentDate = dayjs(dayCell.date);
    if (currentDate.isAfter(minDate)) {
      return ['edl-create'];
    } else {
      return ['edl-no-create'];
    }
  };

  const handleEventClassNames = (arg) => {
    if (arg.event.extendedProps.edl.metadatas.status === 'submit') {
      return arg.event.extendedProps.edl.metadatas.isModifying
        ? ['status-submit', 'status-modified']
        : ['status-submit'];
    }
    if (arg.event.extendedProps.edl.metadatas.status === 'waiting') return ['status-waiting'];
    if (arg.event.extendedProps.edl.metadatas.status === 'assign') return ['status-assign'];
    if (arg.event.extendedProps.edl.metadatas.status === 'control') return ['status-control'];
    if (arg.event.extendedProps.edl.metadatas.status === 'finish') return ['status-finish'];
  };

  const handleEventDidMount = (event) => {
    const element = event.el;
    if (element.classList.contains('status-modified')) {
      const iconElement = document.createElement('div');
      iconElement.className = 'lock-icon-container';
      const root = createRoot(iconElement);
      root.render(<LockIcon />);
      element.appendChild(iconElement);
    }

    element.addEventListener('mouseover', function (event) {
      const ancestor = element.closest('.fc-day');
      if (ancestor.classList.contains('edl-create')) {
        ancestor.classList.add('on-event');
      }
    });
    element.addEventListener('mouseout', function (event) {
      const ancestor = element.closest('.fc-day');
      if (ancestor.classList.contains('edl-create')) {
        ancestor.classList.remove('on-event');
      }
    });
  };

  return (
    <Box sx={{ padding: 1 }}>
      <ToolBar
        viewTitle={viewTitle}
        setViewTitle={setViewTitle}
        typeView={typeView}
        handleTypeView={handleTypeView}
        calendarRef={calendarRef}
        handleStatus={handleStatus}
      />

      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        timeZone="Europe/Paris"
        locale="fr"
        initialView="dayGridMonth"
        dayMaxEventRows={true}
        aspectRatio={1.7}
        height={650}
        slotMinTime="07:00:00"
        slotMaxTime="22:00:00"
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        firstDay={1}
        showNonCurrentDates={false}
        fixedWeekCount={true}
        dayCellClassNames={handleDayCellClassNames}
        eventClassNames={handleEventClassNames}
        eventDidMount={handleEventDidMount}
        headerToolbar={{
          left: '',
          center: '',
          right: '',
        }}
        viewDidMount={(calendar) => {
          setViewTitle(calendar.view.title);
        }}
        events={events}
        eventContent={(info) => {
          const timeText = info.timeText ? info.timeText + ' - ' : '';
          return {
            html: '<div class="fc-div-content">' + timeText + info.event.title + '</div>',
          };
        }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }}
        moreLinkContent={(arg) => {
          return arg.num + ' EDLs';
        }}
      />

      {messageDialog !== null && (
        <ConfirmlDialogSky open={true} handleConfirmClose={handleConfirmClose} btn={messageDialog[0]}>
          {messageDialog[1]}
        </ConfirmlDialogSky>
      )}
    </Box>
  );
}

export default ViewCalendar;
