import { getFormatShortDateFr } from '../../utils';

//FONCTION DE FILTRE ET DE TRI
export function filterAndSortEdls(edls, status, sortBy = 'date', sortOrder = 'asc', searchText = '') {
  return Object.entries(edls)
    .filter((card) => {
      return status.includes(card[1].metadatas.status);
    })
    .filter((card) => {
      const text = searchText.toLowerCase();
      const date = getFormatShortDateFr(card[1].manage.dateEDL);
      return text !== ''
        ? date.includes(text) ||
            card[1].manage.typeEDL.toLowerCase().includes(text) ||
            card[1].possession.typeHouse.toLowerCase().includes(text) ||
            card[1].possession.typeRent.toLowerCase().includes(text) ||
            card[1].possession.nbRoom.toLowerCase().includes(text) ||
            String(card[1].possession.area).toLowerCase().includes(text) ||
            card[1].possession.address.street.toLowerCase().includes(text) ||
            (card[1].possession.address?.complement != null &&
              card[1].possession.address.complement.toLowerCase().includes(text)) ||
            card[1].possession.address.locality.toLowerCase().includes(text) ||
            card[1].possession.address.zipCode.toLowerCase().includes(text) ||
            card[1].tenants[0].civility.toLowerCase().includes(text) ||
            (card[1].tenants[0]?.name != null && card[1].tenants[0].name.toLowerCase().includes(text)) ||
            card[1].tenants[0].surname.toLowerCase().includes(text) ||
            (card[1].tenants[0]?.email != null && card[1].tenants[0].email.toLowerCase().includes(text)) ||
            card[1].tenants[0].phone.toLowerCase().includes(text)
        : true;
    })
    .sort((a, b) => {
      if (sortBy === 'date') {
        return sortOrder === 'asc'
          ? new Date(a[1].manage.dateEDL) - new Date(b[1].manage.dateEDL)
          : new Date(b[1].manage.dateEDL) - new Date(a[1].manage.dateEDL);
      } else if (sortBy === 'locality') {
        const result = a[1].possession.address.locality.localeCompare(b[1].possession.address.locality);
        if (sortOrder === 'asc' && result !== 0) {
          return result;
        } else if (sortOrder === 'desc' && result !== 0) {
          return -result;
        } else {
          return new Date(b[1].manage.dateEDL) - new Date(a[1].manage.dateEDL);
        }
      }
      return 1;
    });
}
