import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

export default function ButtonGroupStatus({ handleStatus, profil }) {
  const [btnSubmit, setBtnSubmit] = useState('submit');
  const [btnWaiting, setBtnWaiting] = useState('waiting');
  const [btnAssign, setBtnAssign] = useState('assign');
  const [btnControl, setBtnControl] = useState('control');
  const [btnFinish, setBtnFinish] = useState('finish');
  const arrayStatus = useRef(['submit', 'waiting', 'assign', 'control', 'finish']);

  const removeStatus = (btn) => {
    console.log(btn);
    if (btn === 'submit') setBtnSubmit(btn + 'Light');
    if (btn === 'waiting') setBtnWaiting(btn + 'Light');
    if (btn === 'assign') setBtnAssign(btn + 'Light');
    if (btn === 'control') setBtnControl(btn + 'Light');
    if (btn === 'finish') setBtnFinish(btn + 'Light');
    const indexToRemove = arrayStatus.current.indexOf(btn);
    if (indexToRemove !== -1) {
      arrayStatus.current.splice(indexToRemove, 1);
    }
    handleStatus(arrayStatus.current);
  };

  const addStatus = (btn) => {
    console.log(btn);
    if (btn === 'submitLight') setBtnSubmit(btn.replace('Light', ''));
    if (btn === 'waitingLight') setBtnWaiting(btn.replace('Light', ''));
    if (btn === 'assignLight') setBtnAssign(btn.replace('Light', ''));
    if (btn === 'controlLight') setBtnControl(btn.replace('Light', ''));
    if (btn === 'finishLight') setBtnFinish(btn.replace('Light', ''));
    const newStatus = btn.replace('Light', '');
    arrayStatus.current.push(newStatus);
    handleStatus(arrayStatus.current);
  };

  const handleClick = (btn) => {
    btn === 'submit' || btn === 'waiting' || btn === 'assign' || btn === 'control' || btn === 'finish'
      ? removeStatus(btn)
      : addStatus(btn);
  };

  return (
    <ButtonGroup variant="contained" aria-label="Disabled elevation buttons">
      <Button color={btnSubmit} onClick={() => handleClick(btnSubmit)}>
        {profil.company.type === 'admin' ? 'a attribuer' : profil.company.type === 'provider' ? 'a traiter' : 'soumis'}
      </Button>
      {profil.company.type === 'admin' && (
        <Button color={btnWaiting} onClick={() => handleClick(btnWaiting)}>
          Réponse
        </Button>
      )}

      <Button color={btnAssign} onClick={() => handleClick(btnAssign)}>
        {profil.company.type === 'provider' ? 'en cours' : 'Attribué'}
      </Button>

      {['admin', 'provider'].includes(profil.company.type) && (
        <Button color={btnControl} onClick={() => handleClick(btnControl)}>
          Contrôle
        </Button>
      )}

      <Button color={btnFinish} onClick={() => handleClick(btnFinish)}>
        Terminé
      </Button>
    </ButtonGroup>
  );
}
