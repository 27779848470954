import React, { useRef, useState, useEffect, useMemo, Fragment } from 'react';

import Grid from '@mui/material/Unstable_Grid2/Grid2';

import FeedIcon from '@mui/icons-material/Feed';
import InfiniteScroll from 'react-infinite-scroller';
import { PaperMyEdls } from '../../Theme';
import { Typography, Divider } from '@mui/material';
import { getStylesPapers } from '../../Theme';
import { capitalizeFirstLetter, normalizePhoneNumber, getFormatShortDateFr, getFormatLongDateFr } from '../../utils';

import _ from 'lodash';

import ToolBar from './Toolbar';
import { filterAndSortEdls } from './utils';

function ViewItem({ edls, countEdls, handleOnClick, typeView, setTypeView, display }) {
  const page = useRef(0);
  const hasMoreItems = useRef(true);
  const filterEdls = useRef({});
  const visibleEdls = useRef({});
  const nbVisibleEdls = useRef(0);
  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState(['submit', 'waiting', 'assign', 'finish']);
  const [sortBy, setSortBy] = useState('date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [countRefresh, setCountRefresh] = useState(0);
  const [fixedElementHeight, setFixedElementHeight] = useState(0);

  let dateEdl = null;

  useEffect(() => {
    const handleResize = () => {
      const fixedElement = document.getElementById('fixedElement');
      if (fixedElement) {
        setFixedElementHeight(fixedElement.offsetHeight - 18);
      }
    };
    handleResize();
    const throttledResizeHandler = _.throttle(handleResize, 200);
    window.addEventListener('resize', throttledResizeHandler);
    return () => {
      window.removeEventListener('resize', throttledResizeHandler);
      throttledResizeHandler.cancel();
    };
  }, [display, countEdls]);

  const handleStatus = (status) => {
    setStatus([...status]);
  };

  const handleTypeView = (typeView) => {
    setTypeView(typeView);
  };

  const handleSearchText = (text) => {
    setSearchText(text);
  };

  const handleSort = ([sortBy, sortOrder]) => {
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  };

  //FONCTION DE RAPPEL POUR CHAQUE NOUVELLE PAGE (SCROLL INFINITE)
  function loadMoreEdls() {
    const startIndex = page.current * 20;
    const endIndex = startIndex + 19;

    const elementsToLoad = Object.fromEntries(filterEdls.current.slice(startIndex, endIndex + 1));
    visibleEdls.current = { ...visibleEdls.current, ...elementsToLoad };
    nbVisibleEdls.current = Object.keys(visibleEdls.current).length;
    // Indiquez s'il y a encore plus d'éléments à charger
    hasMoreItems.current = endIndex < Object.keys(filterEdls.current).length - 1;
    page.current = page.current + 1;
    setCountRefresh(countRefresh + 1);
  }

  //RUN DU RENDER
  useMemo(() => {
    filterEdls.current = filterAndSortEdls(edls, status, sortBy, sortOrder, searchText);
    visibleEdls.current = Object.fromEntries(filterEdls.current.slice(0, nbVisibleEdls.current));
    hasMoreItems.current = true;
    page.current = nbVisibleEdls.current
      ? parseInt((nbVisibleEdls.current / 20).toFixed(0)) - 1
      : parseInt((nbVisibleEdls.current / 20).toFixed(0));
  }, [status, sortBy, sortOrder, searchText, edls]);

  useMemo(() => {
    if (Object.keys(nbVisibleEdls.current).length > 0 && display === 'none') {
      page.current = 0;
      const elementsToLoad = Object.fromEntries(filterEdls.current.slice(0, 20));
      visibleEdls.current = { ...elementsToLoad };
      hasMoreItems.current = true;
    }
  }, [display]);

  const getColor = (metadatas) => {
    return getStylesPapers(metadatas.status ?? 'submit');
  };

  return (
    <>
      {/*
            <Grid xs={12} textAlign="center">
              Nb EDL selectionnée : {Object.keys(visibleEdls.current).length}{" "}
              <br />
              Nb EDL TOTAL: {Object.keys(filterEdls.current).length}
              <br />
              Nb TOTAL EDL en base de données : {Object.keys(edls).length}
            </Grid>
          </Grid>
       */}

      <Grid container direction="column">
        <Grid
          id="fixedElement"
          sx={{
            padding: 2,
            marginLeft: 2,
            marginTop: 0,
            position: 'fixed',
            left: { xs: 0, sm: 240 },
            top: 48,
            width: '100%',
            zIndex: 1000,
            backgroundColor: '#f5f5f5',
            flexWrap: 'wrap',
          }}
        >
          <ToolBar
            viewTitle={null}
            setViewTitle={null}
            typeView={typeView}
            handleTypeView={handleTypeView}
            calendarRef={null}
            handleStatus={handleStatus}
            handleSort={handleSort}
            handleSearchText={handleSearchText}
          />
        </Grid>
        <Grid
          style={{
            marginTop: fixedElementHeight,
            marginLeft: '8px',
          }}
        >
          <InfiniteScroll
            pageStart={page.current} // spécifiez à partir de quelle page commencer
            loadMore={loadMoreEdls} // passez la fonction qui charge plus d'éléments
            hasMore={hasMoreItems.current} // indiquez s'il y a plus d'éléments à charger
            loader={<div key={0}>Loading ...</div>} // passez un élément de chargement personnalisé
          >
            <Grid container spacing={{ xs: 1, md: 2 }}>
              {Object.entries(visibleEdls.current).map(([key, edl]) => {
                let DateDivider = null;
                const dateFormatee = getFormatLongDateFr(edl.manage.dateEDL);
                if (dateEdl !== dateFormatee) {
                  dateEdl = dateFormatee;
                  DateDivider = (
                    <Grid xs={12} mt={{ xs: 1, md: 2 }}>
                      <Typography variant="body">{dateFormatee}</Typography>
                      <Divider />
                    </Grid>
                  );
                }
                return (
                  <Fragment key={key}>
                    {DateDivider !== null && DateDivider}
                    <Grid xs={12} lg={3}>
                      <PaperMyEdls color={getColor(edl.metadatas)} onClick={() => handleOnClick({ edl })}>
                        <FeedIcon className="icon" />
                        <Typography variant="body" className="title-card">
                          Informations
                        </Typography>
                        <Typography variant="body2">
                          Date : {getFormatShortDateFr(edl.manage.dateEDL)} -{' '}
                          {capitalizeFirstLetter(edl.manage.typeEDL)}
                        </Typography>
                        <Typography variant="body2">
                          {capitalizeFirstLetter(edl.possession.typeHouse)} {edl.possession.typeRent} -
                          {edl.possession.nbRoom} - {edl.possession.area} m2
                        </Typography>
                        <Grid>
                          <Divider />
                        </Grid>
                        <Typography variant="body" className="title-card">
                          Adresse
                        </Typography>
                        <Typography variant="body2" className="cardEdl">
                          {edl.possession.address.street}
                        </Typography>
                        <Typography variant="body2">{edl.possession.address.complement}</Typography>
                        <Typography variant="body2">
                          {edl.possession.address.zipCode} {edl.possession.address.locality}
                        </Typography>
                        <Grid>
                          <Divider />
                        </Grid>
                        <Typography variant="body" className="title-card">
                          Locataire
                        </Typography>
                        <Typography variant="body2">
                          {edl.tenants[0].civility} {edl.tenants[0].name} {edl.tenants[0].surname}
                        </Typography>
                        <Typography variant="body2">
                          Email : {edl.tenants[0].email === '' ? 'non indiqué' : edl.tenants[0].email}
                        </Typography>
                        <Typography variant="body2">
                          Téléphone : {normalizePhoneNumber(edl.tenants[0].phone)}
                        </Typography>
                      </PaperMyEdls>
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
          </InfiniteScroll>
        </Grid>
      </Grid>
    </>
  );
}

export default ViewItem;
